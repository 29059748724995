<template>
  <div class="heatmap-legend-category">
      {{ category.title }}
      <heatmap-legend-category-element 
        v-for="value in category.values"
        :key="`${category.title}-${value.numericValue}`"
        :element="value"
        :category="category"
      />
  </div>
</template>

<script>
import HeatmapLegendCategoryElement from './HeatmapLegendCategoryElement.vue'

export default {
    components: {
        HeatmapLegendCategoryElement
    },

    props: [ 'category' ],

    name: 'heatmap-legend-category',

    computed: {
    },

    methods: {
    }
}
</script>

<style>
.heatmap-legend-category {
    width: 100%;
}
</style>