export default ({
    categoryDetails,
    sampleMeta,
}) => {
    let clinicalData = []

    categoryDetails.forEach((category) => {
        const k_label_v_value = Object.fromEntries(
            category.values.map(el => 
                [el.label, el.numericValue]
            )
        )

        let colorscale = category.values.map((val, i) => {
            const opts = category.values.length - 1
            return [i/opts, val.color]
        })
        
        const zArr = sampleMeta.map(sample => 
            k_label_v_value[sample[category.title]]
        )

        let data = {
            z: [zArr],
            x: sampleMeta.map(el => el.caseId),
            y: [category.title],
            type: 'heatmap',
            hoverongaps: false,  
            showscale: false,
            colorscale,
            zmax: Math.max(...Object.values(k_label_v_value)),
            zmin: Math.min(...Object.values(k_label_v_value)),
        }

        clinicalData.push(data)
    })

    return clinicalData
}