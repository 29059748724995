<template>
  <div class="input-sample-dashboard-graph-order-container">
      <draggable v-model="categoryDetails" @start="drag=true" @end="drag=false">
        <div 
            class="input-sample-dashboard-graph-order"
            v-for="element in categoryDetails" 
            :key="element.title"
        >
        <v-icon>
            mdi-drag-vertical
        </v-icon>
            {{element.title}}
        </div>
      </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: "input-sample-dashboard-graph-order-container",

    components: {
        draggable,
    },

    computed: {
        categoryDetails: {
            get() { return this.$store.state.categoryDetails.filter(el => el.title !== 'Tumor') },
            set(categoryDetails) { this.$store.dispatch('setCategoryDetails', { categoryDetails }) },
        },
    },
}
</script>

<style>
.input-sample-dashboard-graph-order-container {
    width: 100%;
    margin-top: 10px;
}

.input-sample-dashboard-graph-order {
    border: solid 1px gray;
    margin-bottom: 2px;
    padding: 5px;
    cursor: grab;
    display: flex;
    flex-direction: row;
}
</style>