import firebase from "firebase/app";
import 'firebase/database'

const env = process.env
const cohort = env.VUE_APP_COHORT

const firebaseConfig = {
    apiKey: env.VUE_APP_API_KEY,
    authDomain: env.VUE_APP_AUTH_DOMAIN,
    databaseURL: env.VUE_APP_DB_URL,
    projectId: env.VUE_APP_PROJECT_ID,
    storageBucket: env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: env.VUE_APP_MESSAGING_SENDER_ID,
    appId: env.VUE_APP_APP_ID,
    measurementId: env.VUE_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig)


export const fetchPwIds = async() => {
    const path = `protrack/${cohort}/pathwayIds`
    const ref = firebase.database().ref(path)
    return await ref.once('value')
          .then((snapshot) => {
              const pathways = snapshot.val()
              return pathways
          })
}

export const getPathwayScores = async(pwId) => {
    const path = `protrack/${cohort}/pathwayScores/${pwId}`
    const ref = firebase.database().ref(path)
    return await ref.once('value')
          .then((snapshot) => {
              const pathways = snapshot.val()
              return {id: pwId, scores: pathways}
          })
}
