<template>
    <div class="tumor-selector">
        <div class="tumor-selector-content">
            <div class="tumor-selector-content-header">
                <b>{{ tumor }}</b>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <a 
                        :href="`https://pubmed.ncbi.nlm.nih.gov/${PMID}/`"
                        target="_blank"
                        style="text-decoration: none;"
                        >
                            <v-icon    
                                v-bind="attrs"
                                v-on="on" 
                                small 
                                class="pl-2"
                            >
                                mdi-open-in-new
                            </v-icon>
                            </a>
                        </template>
                    <span>Open published flagship paper</span>
                </v-tooltip>
            </div>
            <img :src="imgSrc" style="height: 60px; width: 60px;">
        </div>
        <v-btn
            @click="toggleTumor"
        >
            {{ tumorIncluded ? 'Hide' : 'Show' }}
        </v-btn>
    </div>
</template>

<script>

export default {
    name: "tumor-selector",
    props: ['tumor'],
    computed: {
        imgSrc() {
            return require(`../assets/heatmap-thumbnail-${this.tumor}.png`)
        },
        tumorIncluded() { return this.$store.state.tumorsFiltered[this.tumor] },
        PMID() { return this.$store.state.tumorPMID[this.tumor] },
    },
    methods: {
        toggleTumor() { this.$store.dispatch('toggleTumor', { tumor: this.tumor, show: !this.tumorIncluded }) }
    }
}
</script>

<style scoped>
    .tumor-selector {
        border: solid 1px lightgray;
        /* padding: 10px 10px 2px 10px; */
        margin: 5px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* position: relative; */
        /* padding: 10px; */
    }

    .tumor-selector-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 auto;
    }

    .tumor-selector-content-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .hide-button {
        margin-bottom: auto; 
        cursor: pointer;
        /* position:absolute;
        bottom:0; */
    }
</style>