<template>
  <div class="input-sample-dashboard-cohort-count">
      <b>{{ tumor }}</b>: {{ tumorSamples.length }}
      <div style="padding-left: 10px" v-if="tumorSamples.length > 0">
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="indigo"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="copySamples"
                >
                mdi-content-copy
                </v-icon>
            </template>
            <span>{{ !this.copied ? 'Copy samples to clipboard' : 'Copied' }}</span>
        </v-tooltip>

        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="teal"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="setCustomCohort"
                >
                mdi-table-arrow-right
                </v-icon>
            </template>
            <span>{{ 'Show on heatmap' }}</span>
        </v-tooltip>
      </div>
  </div>
</template>

<script>
export default {
    name: "input-sample-dashboard-cohort-count",
    
    props: [ 'tumor' ],

    data() {
        return {
            copied: false,            
        }
    },

    computed: {
        sampleMeta() { return this.$store.state.sampleMeta },
        tumorSamples() {
            return this.sampleMeta
                .filter(meta => meta.Tumor === this.tumor)
                .map(meta => meta.caseId)
                .filter(sample => !this.excludedSamplesAll.has(sample))
        },
        excludedSamplesAll() { return new Set(Object.values(this.$store.state.excludedSamples).flat()) },
        excludedSamples() { return this.$store.state.excludedSamples },
    },

    watch: {
        tumorSamples() { this.copied = false }
    },

    methods: {
        copySamples() {
            navigator.clipboard.writeText(this.tumorSamples);
            this.copied = true
        },
        setCustomCohort() {
            this.$store.dispatch('setCustomCohort', { customCohort: this.tumorSamples })
            this.$store.dispatch('toggleUseCustomCohort', { useCustomCohort: true })
            this.$store.dispatch('setView', { view: 'Heatmap' })
        },
    },
}
</script>

<style>
.input-sample-dashboard-cohort-count {
    width: 100%;
    display: flex;
    flex-direction: row;
}
</style>