<template>
    <div class="view-selector">
        <v-select
          :items="pathwayDatabases"
          filled
          v-model="pathwayDatabase"
          label="Pathway database"
          dense
        >
            <template v-slot:item="data">
                {{ data.item }}
                <!-- <v-tooltip v-if="data.item.includes('Cis-regulation')" right color="#3F51B5">
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="#546E7A"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        small
                    >
                        mdi-help-circle
                    </v-icon>
                    </template>
                    <div>
                        Identify associations between<br>
                        DNA-level alterations<br> 
                        and protein/RNA/phosphoprotein <br> 
                        expression levels.
                    </div>
                </v-tooltip> -->
            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: "pathway-db-selector",

    computed: {
        pathwayDatabase: {
            get() { return this.$store.state.pathwayDatabase },
            set(pathwayDatabase) { this.$store.dispatch('setPathwayDb', { pathwayDatabase }) },
        },
        pathwayDatabases() {
            return  this.$store.state.pathwayDatabases
        },
    },
}
</script>

<style scoped>
    .view-selector {
        max-width: 400px;
        min-width: 400px;
        border-top: none;
        border-right: solid 1px lightgray;
        padding: 10px;
        margin-bottom: -30px;
    }

    /* .view-selector > div {
        margin-bottom: 1em;
    } */

</style>