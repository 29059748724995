<template>
  <div class="input-sample-dashboard-cohort-count-container">
      <input-sample-dashboard-cohort-count 
        v-for="tumor in tumors"
        :key="tumor"
        :tumor="tumor"
      />
      <input-sample-dashboard-cohort-count-total />
      <!-- <b>Total:</b> {{ totalFilteredSamples.length }} -->
     <div style="margin-top: 20px;">(Click graph legends to filter cases)</div>

  </div>
</template>

<script>
import InputSampleDashboardCohortCount from './InputSampleDashboardCohortCount.vue'
import InputSampleDashboardCohortCountTotal from './InputSampleDashboardCohortCountTotal.vue'
export default {
  components: { InputSampleDashboardCohortCount, InputSampleDashboardCohortCountTotal },
    name: "input-sample-dashboard-cohort-count-container",
    
    computed: {
        excludedSamplesAll() { return new Set(Object.values(this.$store.state.excludedSamples).flat()) },
        excludedSamples() { return this.$store.state.excludedSamples },
        sampleMeta() { return this.$store.state.sampleMeta },
        totalFilteredSamples() {
            return Object.entries(this.sampleMeta)
                .map(([sample, ]) => sample)
                .filter(sample => !this.excludedSamplesAll.has(sample))
        },
        tumors() { return this.$store.state.tumors.filter(tumor => this.tumorsFiltered[tumor]) },
        tumorsFiltered() { return this.$store.state.tumorsFiltered },
    },
}
</script>

<style>
.input-sample-dashboard-cohort-count-container {
    width: 100%;
    padding: 10px;
}
</style>