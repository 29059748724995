<template>
    <div class="tumor-selector-container">
        <v-expansion-panels v-model="panel">
            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">
                    <b>Hide or show tumor types:</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="tumor-selector-parent">
                        <tumor-selector 
                            v-for="tumor in tumors" 
                            :key="tumor" 
                            :tumor="tumor" 
                        />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import TumorSelector from './TumorSelector.vue'

export default {
    components: {
        TumorSelector
    },
    name: "tumor-selector-container",
    data() {
        return {
            filteredTumors: [],
            panel: 0,
        }
    },
    computed: {
        // filteredTumors() { return},
        tumors() {
            return this.$store.state.tumors
        },
    },
}
</script>

<style scoped>
    .tumor-selector-container {
        display: flex;
        flex-direction: row;
        padding: 5px;
        height: auto;
        width: 100%;
        min-width: 900px;
    }

    .tumor-selector-parent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        min-width: 900px;
    }
</style>