<template>
    <div class="pathways-selected">
        <!-- This is pathways selected - {{ heatmapPathways }} -->
    </div>
</template>

<script>
export default {
    name: 'pathways-selected',

    computed: {
        heatmapPathways() { return this.$store.state.heatmapPathways },
    },

}
</script>

<style>
.pathways-selected {
    width: 100%;
}
</style>