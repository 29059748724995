<template>
  <div class="barplotly-container">
      <div :id="divId">
      </div>
  </div>
</template>

<script>
import barplot from '../plotly/barplot'

export default {
    name: 'barplotly-container',

    props: [ 'category' ],

    data: () => ({
    }),

    computed: {
        divId() { 
            return `barplot-${this.category.title.split(' ').join('-')}` 
        },
        percentageView() { return this.$store.state.percentageView },
        sampleMeta() { return this.$store.state.sampleMeta },
        tumorsFiltered() { return this.$store.state.tumorsFiltered },
        tumors() { 
            return this.$store.state.tumors.filter(tumor => this.tumorsFiltered[tumor] ) 
        },
  
    },


    watch: {
        tumors() { this.generateBarplot() },
        percentageView() { this.generateBarplot() },
    },

    methods: {
        generateBarplot() {
            let plot = barplot({
                category: this.category,
                excludedSamples: this.excludedSamples,
                divId: this.divId,
                percentageView: this.percentageView,
                sampleMeta: this.sampleMeta,
                tumors: this.tumors,
            }) 
            if (plot) {
                const vm = this
                const divId = this.divId
                const category = this.category.title

                plot.on('plotly_afterplot', function(){                          
                    const traces = document.getElementById(divId).data
                    const excludedSamples = traces
                        .filter(el => el.visible && el.visible === 'legendonly')
                        .map(el => el.customData.flat())
                        .flat()

                    vm.updateCohort({ category, excludedSamples })
                })
            }
        },
        updateCohort({ category, excludedSamples }) {
            this.$store.dispatch(
                'setExcludedSamples', 
                { category, excludedSamples }
            )
        },
    },

    mounted() { 
        this.generateBarplot()
    }
}
</script>

<style>
.barplotly-container {
    width: 100%;
    /* border: solid 1px #ebebeb; */
}
</style>