<template>
  <div class="input-sample-dashboard-cohort-count-total">
      <b>Total</b>: {{ totalFilteredSamples.length }}
      <div style="padding-left: 10px" v-if="totalFilteredSamples.length > 0">
        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="indigo"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="copySamples"
                >
                mdi-content-copy
                </v-icon>
            </template>
            <span>{{ !this.copied ? 'Copy samples to clipboard' : 'Copied' }}</span>
        </v-tooltip>


        <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="teal"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="setCustomCohort"
                >
                mdi-table-arrow-right
                </v-icon>
            </template>
            <span>{{ 'Show on heatmap' }}</span>
        </v-tooltip>
      </div>
  </div>
</template>

<script>
export default {
    name: "input-sample-dashboard-cohort-count-total",
    
    // props: [ 'tumor' ],

    data() {
        return {
            copied: false,            
        }
    },

    computed: {
        excludedSamplesAll() { return new Set(Object.values(this.$store.state.excludedSamples).flat()) },
        excludedSamples() { return Object.fromEntries(this.$store.state.excludedSamples.map(el => [el, true])) },
        sampleMeta() { return this.$store.state.sampleMeta },
        tumorsFiltered() { return this.$store.state.tumorsFiltered },
        totalFilteredSamples() {
            return this.sampleMeta
                .filter(meta => this.tumorsFiltered[meta.Tumor])
                .map(meta => meta.caseId)
                .filter(sample => !this.excludedSamplesAll.has(sample))
        },
    },

    watch: {
        totalFilteredSamples() {
            this.copied = false 
        }
    },

    methods: {
        copySamples() {
            navigator.clipboard.writeText(this.totalFilteredSamples);
            this.copied = true
        },
        setCustomCohort() {
            this.$store.dispatch('setCustomCohort', { customCohort: this.totalFilteredSamples })
            this.$store.dispatch('toggleUseCustomCohort', { useCustomCohort: true })
            this.$store.dispatch('setView', { view: 'Heatmap' })
        },
    },

}
</script>

<style>
.input-sample-dashboard-cohort-count-total {
    width: 100%;
    display: flex;
    flex-direction: row;
}
</style>