<template>
  <div class="heatmap-legend">
      <div class="heatmap-legend-title">
        <b>Interactive legend</b>
        <p>Click elements to hide/show on heatmap</p>
      </div>
      <v-row
        :align="'start'"
        no-gutters
        style="height: 150px;"
      >
        <v-col>    
            <heatmap-legend-category 
                v-for="category in categoryDetails.filter((el, i) => i % 2 === 0)"
                :key="category.title"
                :category="category"
            />
        </v-col>
        <v-col>    
            <heatmap-legend-category 
                v-for="category in categoryDetails.filter((el, i) => i % 2 > 0)"
                :key="category.title"
                :category="category"
            />
        </v-col>
      </v-row>

  </div>
</template>

<script>
import HeatmapLegendCategory from './HeatmapLegendCategory'

export default {
    components: {
        HeatmapLegendCategory,
    },

    name: 'heatmap-legend',

    computed: {
        categoryDetails() { return this.$store.state.categoryDetails },
    },
}
</script>

<style>
.heatmap-legend {
    width: 400px;
    margin-left: 20px;
}

.heatmap-legend-title {
    text-align: center;
}
</style>