<template>
  <div class="heatmap-container">
      <div id="heatmap-container"></div>
      <heatmap-legend />
  </div>
</template>

<script>
import heatmap from '../plotly/heatmap'
import HeatmapLegend from './HeatmapLegend.vue'
 

export default {
  components: { HeatmapLegend },
    name: 'heatmap-container',

    computed: {
        caseId() { return this.$store.state.caseId },
        categoryDetails() { return this.$store.state.categoryDetails },
        combinedHeatmapData() { return this.$store.state.combinedHeatmapData },
        heatmapShow() { return this.$store.state.heatmapShow },
        normalizeCombined() { return this.$store.state.normalizeCombined },
        pathwayIds() { return this.$store.state.pathwayIds },
        heatmapPathways() { return this.$store.state.heatmapPathways },
        sampleMeta() { return this.$store.state.sampleMeta },
        combinedHeatmapDataFiltered() {
            let combinedHeatmapDataFiltered = []

            this.combinedHeatmapData.forEach((sample) => {
                let show = Object.keys(this.heatmapShow)
                    .map(key => this.heatmapShow[key][sample[key]])
                    .every(el => el)
                // sampleShow[sample.caseId] = show
                if (show) {
                    combinedHeatmapDataFiltered.push(sample)
                }
            })

            return combinedHeatmapDataFiltered
        },
        customCohort() { return this.useCustomCohort ? 
            this.$store.state.heatmapCustomCohort : null
        },
        useCustomCohort() { return this.$store.state.useCustomCohort },
    },

    watch: {
        combinedHeatmapDataFiltered() { this.generateHeatmap() },
        customCohort() { this.generateHeatmap() },
        normalizeCombined() { this.generateHeatmap() },
    },

    methods: {
        generateHeatmap() {
            heatmap({
                caseIds: this.caseId,
                categoryDetails: this.categoryDetails,
                combinedHeatmapData: this.combinedHeatmapDataFiltered,
                customCohort: this.customCohort,
                divId: 'heatmap-container',
                normalizeCombined: this.normalizeCombined,
                heatmapPathways: this.heatmapPathways,
            })
        },
    },

    mounted() {
        this.generateHeatmap()
        this.$store.dispatch(
            'sortSamples', 
            { 
                tracksToLock: ['Tumor'] 
            }
        )
    },
}
</script>

<style>
.heatmap-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}
</style>