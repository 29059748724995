import fillerObj from './fillerObj'
import generateLayout from './layout/generateLayout'
import heatmapClinical from './heatmapClinical'


export default ({
    categoryDetails,
    combinedHeatmapData,
    customCohort,
    divId,
    heatmapPathways,
    normalizeCombined,
}) => {
    const Plotly = window.Plotly

    if (customCohort) {
        combinedHeatmapData = deepClone(combinedHeatmapData).filter(meta => customCohort.includes(meta.caseId))
    } 

    const x = combinedHeatmapData.map(sample => sample.caseId)

    let clinical = heatmapClinical({
        categoryDetails,
        sampleMeta: combinedHeatmapData,
    })
    clinical.reverse()

    let data = []

    if (heatmapPathways.length > 0) {
        let pwData = {
            z: [],
            x,
            y: [],
            type: 'heatmap',
            hoverongaps: false,  
            showscale: true,
            colorbar: {
                title: 'zscore',
                lenmode: 'pixels',
                len: 200,
                x: 1,
                y: 0.75,
            }
        }
    
        heatmapPathways.forEach(pathway => {
            pwData.y.push(pathway)
    
            const suffix = normalizeCombined ? 'TNcombined' : 'TNindependent'
            
            const zScores = combinedHeatmapData.map(sample => sample[`${pathway} ${suffix}`])
            
            pwData.z.push(zScores)
        })
    
        data.push(pwData)
    }

    data = [
        ...data, 
        fillerObj({
            i: 1,
            marker: '*',
            samples: x,
        }),
        ...clinical
    ]

    data.forEach((track) => {
        if (track) {
            track.z.splice(0,0,[])
            track.y.splice(0,0,'')
            track.xgap = 0.1
        }
      })

    const layout = generateLayout(data)

    const config = {
        responsive: true,
    }

    Plotly.newPlot(divId, data, layout, config)
}


function deepClone(o) {
    return JSON.parse(JSON.stringify(o))
}
