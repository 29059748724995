import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import arr from '@/plotly/arr-stats'
import caseId from '@/refs/caseId'
import sampleMeta from '@/refs/sampleMeta'
import categoryDetails from '@/refs/categoryDetails'

import sortFn from './sortFn'


let tumors = categoryDetails
  .find(el => el.title === 'Tumor')
  .values.map(el => el.label)
tumors.sort(function (a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
})

let k_label_v_value = {}
let heatmapShow = {}
categoryDetails.forEach((category) => {
  k_label_v_value[category.title] = Object.fromEntries(category.values.map(el => [el.label, el.numericValue]))
  heatmapShow[category.title] = Object.fromEntries(category.values.map(el => [el.label, true]))
})

import { fetchPwIds, getPathwayScores } from '../firebase'

let sampleMetaNumerical = []

sampleMeta.forEach((sample, i) => {
  let sampleMetaNumericalObj = {
    ...sample,
  }
  Object.entries(k_label_v_value).forEach(([label, value]) => {
      sampleMetaNumericalObj[`${label}_num`] = parseInt(value[sample[label]])
  })
  sampleMetaNumerical.push(sampleMetaNumericalObj) 
})

export default new Vuex.Store({
  state: {
    activeTabHeatmap: 1,
    combinedHeatmapData: deepClone(sampleMetaNumerical),
    caseId,
    categoryDetails,
    excludedSamples: {},
    heatmapCustomCohort: ['C3N-01907', 'C3L-03350', 'C3N-01900', 'C3L-03129', 'C3N-01379', 'C3L-03371', 'C3L-03513', 'C3L-03514', 'C3L-03515', 'C3L-07032', 'C3L-07033', 'C3L-07034', 'C3L-07035', 'C3L-07036'],
    heatmapPathways: [],
    heatmapShow,
    heatmapTracksToLock: [
      'Tumor',
    ],
    k_label_v_value,
    normalizeCombined: true,
    pathwayDatabase: 'Hallmark',
    pathwayDatabases: [
      'Hallmark', 
      'KEGG', 
      'Reactome'
    ],
    pathwayIds: [],
    pathwayScores: [],
    percentageView: true,
    sampleMeta: sampleMetaNumerical,
    tumors: tumors,
    tumorPMID: {
      'BRCA': '33212010',
      'ccRCC': '31675502',
      'COAD': '31031003',
      'GBM': '33577785',
      'HNSCC': '33417831',
      'LSCC': '34358469',
      'LUAD': '32649874',
      'HGSC': '27372738',
      'PDA': '34534465',
      'UCEC': '32059776'
    },
    tumorsFiltered: Object.fromEntries(tumors.map(tumor => [tumor, true])),
    useCustomCohort: false,
    view: 'Sample dashboard',
    views: ['Heatmap', 'Sample dashboard'],
  },
  mutations: {
    SET_CATEGORY_DETAILS(state, { categoryDetails }) {
      state.categoryDetails = categoryDetails
    },
    SET_COMBINED_HEATMAP_DATA(state, { combinedHeatmapData }) {
      state.combinedHeatmapData = combinedHeatmapData
    },
    SET_CUSTOM_COHORT(state, { customCohort }) {
      state.heatmapCustomCohort = customCohort
    },
    SET_EXCLUDED_SAMPLES(state, { category, excludedSamples }) {
      let update = deepClone(state.excludedSamples)
      update[category] = excludedSamples
      state.excludedSamples = update
    },
    SET_HEATMAP_PATHWAYS(state, { heatmapPathways }) {
      state.heatmapPathways = heatmapPathways
    },
    SET_HEATMAP_TRACKS_TO_LOCK(state, { tracksToLock }) {
      state.heatmapTracksToLock = tracksToLock
    },
    SET_NORMALIZE_COMBINED(state, { normalizeCombined }) {
      state.normalizeCombined = normalizeCombined
    },
    SET_PATHWAY_DATABASE(state, { pathwayDatabase }) {
      state.pathwayDatabase = pathwayDatabase
    },
    SET_PATHWAY_IDS(state, { pwIds }) {
      state.pathwayIds = pwIds
    },
    SET_PATHWAY_SCORES(state, { pathwayScores }) {
      state.pathwayScores = pathwayScores
    },
    SET_SAMPLE_META_SORTED(state, { sampleMetaSorted }) {
      state.sampleMetaSorted = sampleMetaSorted
    },
    SET_VIEW(state, { view }) {
      state.view = view
    },
    TOGGLE_LEGEND_ELEMENT(state, { category, label }) {
      let update = deepClone(state.heatmapShow)
      update[category][label] = !update[category][label]
      state.heatmapShow = update
    },
    TOGGLE_PERCENTAGE_VIEW(state, { percentageView }) {
      state.percentageView = percentageView
    },
    TOGGLE_TUMOR(state, { tumor, show }) {
      let update = deepClone(state.tumorsFiltered)
      update[tumor] = show
      state.tumorsFiltered = update
    }, 
    TOGGLE_USE_CUSTOM_COHORT(state, { useCustomCohort }) {
      state.useCustomCohort = useCustomCohort
    },
    UPDATE_ACTIVE_TAB_HEATMAP(state, { activeTabHeatmap }) {
      state.activeTabHeatmap = activeTabHeatmap
    },
  },
  actions: {
    async fetchPwIds(store) {
      const pwIds = await(fetchPwIds())
      store.commit('SET_PATHWAY_IDS', { pwIds })

    //   store.dispatch('setPathwaysHeatmap', { pathways: [     {
    //     "db": "Hallmark",
    //     "id": 2,
    //     "members": 200,
    //     "name": "HALLMARK_ADIPOGENESIS"
    // }] 
    // })

    },
    normalizePathwayScores(store, { pathwayScores }) {
      let pathwayIds = deepClone(store.state.pathwayIds)

      let caseIds = deepClone(store.state.caseId)
      let combinedHeatmapData = deepClone(store.state.combinedHeatmapData)
      
      let heatmapPathways = []

      pathwayScores.forEach(pathwayScore => {
        const pathway = pathwayIds.find(el => el.id === pathwayScore.id)
        const normalName = `${pathway.name} N`
        const tumorName = `${pathway.name} T`

        heatmapPathways = [...heatmapPathways, normalName, tumorName]

        const tumorScores = Object.fromEntries(
          caseIds.map(caseId => {
            return [
              caseId.caseId, 
              pathwayScore.scores[caseId.tumor_sample_id_protein]
            ]
        }))

        const normalScores = Object.fromEntries(
          caseIds.map(caseId => {
            return [
              caseId.caseId, 
              pathwayScore.scores[caseId.normal_sample_id_protein]
            ]
        }))

        const allScores = [
          ...Object.values(normalScores),
          ...Object.values(tumorScores),
        ]
            
        const combinedZScoreDict = zScoreDict(allScores)
        const tumorZScoreDict = zScoreDict(Object.values(tumorScores))
        const normalZScoreDict = zScoreDict(Object.values(normalScores))

        combinedHeatmapData.forEach(sample => {
          const tumorZScoreCombined = combinedZScoreDict[tumorScores[sample.caseId]]
          const normalZScoreCombined = combinedZScoreDict[normalScores[sample.caseId]]
          const tumorZScoreIndependent = tumorZScoreDict[tumorScores[sample.caseId]]
          const normalZScoreIndependent = normalZScoreDict[normalScores[sample.caseId]]

          sample[`${tumorName} TNcombined`] = tumorZScoreCombined ? tumorZScoreCombined : null
          sample[`${normalName} TNcombined`] = normalZScoreCombined ? normalZScoreCombined : null
          sample[`${tumorName} TNindependent`] = tumorZScoreIndependent ? tumorZScoreIndependent : null
          sample[`${normalName} TNindependent`] = normalZScoreIndependent ? normalZScoreIndependent : null
            
        })

        store.commit(
          'SET_HEATMAP_PATHWAYS', 
          {
            heatmapPathways
          }
        )
        
        store.commit(
          'SET_COMBINED_HEATMAP_DATA', 
          {
            combinedHeatmapData
          }
        )
    })

    },
    setCategoryDetails(store, { categoryDetails }) {
      store.commit('SET_CATEGORY_DETAILS', { categoryDetails })
    },
    setCustomCohort(store, { customCohort }) {
      store.commit('SET_CUSTOM_COHORT', { customCohort })
    },
    setExcludedSamples(store, { category, excludedSamples }) {
      store.commit('SET_EXCLUDED_SAMPLES', { category, excludedSamples })
    },
    setNormalizeCombined(store, { normalizeCombined }) {
      store.commit('SET_NORMALIZE_COMBINED', { normalizeCombined })
    },
    setPathwayDb(store, { pathwayDatabase }) {
      store.commit('SET_PATHWAY_DATABASE', { pathwayDatabase })
    },
    async setPathwaysHeatmap(store, { pathways }) {
      const pathwayScoresList = pathways.map((pw) => {
        return getPathwayScores(pw.id)
      })

      const pathwayScores = await Promise.all(pathwayScoresList)

      store.dispatch('normalizePathwayScores', { pathwayScores })
      store.commit('SET_PATHWAY_SCORES', { pathwayScores })
    },
    setView(store, { view }) {
      store.commit('SET_VIEW', { view })
    },
    sortSamples(store, { tracksToLock }) {
      
      store.commit('SET_HEATMAP_TRACKS_TO_LOCK', { tracksToLock })
      
      let combinedHeatmapData = deepClone(store.state.combinedHeatmapData)
      const k_label_v_value = store.state.k_label_v_value
      const normalizeCombined = store.state.normalizeCombined

      const suffix = normalizeCombined ? `TNcombined` : `TNindependent`

      // const test = [
      //   {a: 1, 'b with space': 2}, 
      //   {a: 4, 'b with space': 1},  
      //   {a: 2, 'b with space': 1}
      // ]
      // console.log(test.sort(sortFn([['b with space', 'asc'], ['a', 'desc']])))
      
      let sortKeys = tracksToLock.map(track => 
        track in k_label_v_value ? 
        [`${track}_num`] : [`${track} ${suffix}`]
      )

      combinedHeatmapData.forEach(sample => {
        sortKeys.forEach(key => {
          sample[key] = sample[key] ? sample[key] : -1000
        })
      })

      combinedHeatmapData.sort(sortFn(sortKeys))

      combinedHeatmapData.forEach(sample => {
        sortKeys.forEach(key => {
          sample[key] = sample[key] > -1000 ? sample[key] : undefined
        })
      })

      store.commit(
        'SET_COMBINED_HEATMAP_DATA', 
        { 
          combinedHeatmapData: combinedHeatmapData 
        }
      )
    },
    toggleLegendElement(store, { category, label }) {
      // console.log('categoryLabel: ', category, label)
      store.commit('TOGGLE_LEGEND_ELEMENT', { category, label })
    },
    togglePercentageView(store, { percentageView }) {
      store.commit('TOGGLE_PERCENTAGE_VIEW', { percentageView })
    },
    toggleUseCustomCohort(store, { useCustomCohort }) {
      store.commit('TOGGLE_USE_CUSTOM_COHORT', { useCustomCohort })
    },
    toggleTumor(store, { tumor, show }) { 
      store.commit('TOGGLE_TUMOR', { tumor, show })
    },
    updateActiveTabHeatmap(store, { activeTabHeatmap }) {
      store.commit('UPDATE_ACTIVE_TAB_HEATMAP',  { activeTabHeatmap })
    },
  },
})


function deepClone(o) {
  return JSON.parse(JSON.stringify(o))
}

const zScoreDict = (scoresUnfiltered) => {
  const scores = scoresUnfiltered.filter(el => el)
  return Object.fromEntries(
      arr
          .zScores(scores)
          .map((zScore, i) => [scores[i], zScore])
  )
}