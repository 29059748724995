export default [
  {
    title: "Age",
    values: [
      { numericValue: 3003, label: ">=70 (>=75%)", color: "#741B47", light: true},
      { numericValue: 3002, label: "63-70 (50%-75%)", color: "#9D5F73", light: false},
      { numericValue: 3001, label: "55-63 (25%-50%)", color: "#B98DA3", light: false},
      { numericValue: 3000, label: "<55 (25%)", color: "#E3D1DA", light: false},
      { numericValue: 3004, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Sex",
    values: [
      { numericValue: 2001, label: "Male", color: "#0F4392", light: true},
      { numericValue: 2002, label: "Female", color: "#FF7F7F", light: false},
      // { numericValue: 2000, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Overall survival in days",
    values: [
      { numericValue: 12003, label: ">=1016 (>=75%)", color: "#674EA7", light: true},
      { numericValue: 12002, label: "609-1016 (50%-75%)", color: "#9483C1", light: false},
      { numericValue: 12001, label: "348-609 (25%-50%)", color: "#B3A6D3", light: false},
      { numericValue: 12000, label: "<348 (25%)", color: "#D1C9E4", light: false},
      { numericValue: 12004, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Grade",
    values: [
      { numericValue: 6003, label: "G4", color: "#0B5394", light: true},
      { numericValue: 6002, label: "G3", color: "#5486B4", light: false},
      { numericValue: 6001, label: "G2", color: "#85A9C9", light: false},
      { numericValue: 6000, label: "G1", color: "#CEDCE9", light: false},
      { numericValue: 6004, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Stage",
    values: [
      { numericValue: 7003, label: "IV", color: "#38761D", light: false},
      { numericValue: 7002, label: "III", color: "#5F914A", light: false},
      { numericValue: 7001, label: "II", color: "#9BBA8E", light: false},
      { numericValue: 7000, label: "I", color: "#C3D5BB", light: false},
      { numericValue: 7004, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Recurrence status",
    values: [
      { numericValue: 13001, label: "Yes", color: "#9f3f40", light: false},
      { numericValue: 13000, label: "No", color: "#A0B743", light: false},
      { numericValue: 13002, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "BMI",
    values: [
      { numericValue: 8003, label: ">=30", color: "#B45F06", light: false},
      { numericValue: 8002, label: "25-30", color: "#C37E37", light: false},
      { numericValue: 8001, label: "18.5-25", color: "#D9AF82", light: false},
      { numericValue: 8000, label: "<18.5", color: "#E8CFB4", light: false},
      { numericValue: 8004, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Tobacco use",
    values: [
      { numericValue: 10000, label: "current smoker", color: "#ad2a58", light: true},
      { numericValue: 10001, label: "past-smoker", color: "#DEA9BC", light: false},
      { numericValue: 10002, label: "non-smoker", color: "#9CEBA8", light: false},
      { numericValue: 10003, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Alcohol consumption",
    values: [
      { numericValue: 9000, label: "drinker", color: "#296493", light: false},
      { numericValue: 9001, label: "non-drinker", color: "#1D790E", light: false},
      { numericValue: 9002, label: "past-drinker", color: "#7B6367", light: false},
      { numericValue: 9003, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Tumor",
    values: [
      { numericValue: 1000, label: "UCEC", color: "#CC6677", light: false },
      { numericValue: 1001, label: "PDAC", color: "#332288", light: true},
      { numericValue: 1002, label: "HGSC", color: "#DDCC77", light: false},
      { numericValue: 1003, label: "LUAD", color: "#117733", light: false},
      { numericValue: 1004, label: "LSCC", color: "#88CCEE", light: false},
      { numericValue: 1005, label: "HNSCC", color: "#882255", light: true},
      { numericValue: 1006, label: "GBM", color: "#44AA99", light: false},
      { numericValue: 1007, label: "COAD", color: "#999933", light: false},
      { numericValue: 1008, label: "ccRCC", color: "#AA4499", light: false},
      { numericValue: 1009, label: "BRCA", color:  "#EE3377", light: false},
    ],
  },
  {
    title: "Participant Country",
    values: [
      { numericValue: 5007, label: "Vietnam", color: "#4b4f7a", light: true},
      { numericValue: 5006, label: "United States", color: "#52dea9", light: false},
      { numericValue: 5005, label: "Ukraine", color: "#a297ca", light: false},
      { numericValue: 5004, label: "Russia", color: "#add51f", light: false},
      { numericValue: 5003, label: "Poland", color: "#b90b59", light: false},
      { numericValue: 5002, label: "China", color: "#0a4f4e", light: true},
      { numericValue: 5001, label: "Canada", color: "#b55d57", light: false},
      { numericValue: 5000, label: "Bulgaria", color: "#069668", light: false},
      { numericValue: 5008, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Secondhand smoke exposure",
    values: [
      { numericValue: 11001, label: "Yes", color: "#802639", light: true},
      { numericValue: 11000, label: "No", color: "#41bbc5", light: false},
      { numericValue: 11002, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
  {
    title: "Survival status",
    values: [
      { numericValue: 14001, label: "Dead", color: "#C15809", light: false},
      { numericValue: 14000, label: "Alive", color: "#92B2BA", light: false},
      { numericValue: 14002, label: "not reported", color: "#EBEBEB", light: false},
    ],
  },
];
