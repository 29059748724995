<template>
  <div class="heatmap-custom-cohort">
    <v-switch
      v-model="useCustomCohort"
      :label="`Use custom cohort`"
    ></v-switch>
    <div v-if="useCustomCohort">
        <v-textarea
            clearable
            outlined
            clear-icon="mdi-close-circle"
            label="Cases in custom cohort"
            v-model="customCohortInput"
        ></v-textarea>
        <v-btn
            @click="setCustomCohort"
            small
        >
            Redraw heatmap
        </v-btn>
    </div>
  </div>
</template>

<script>

export default {
    name: 'heatmap-custom-cohort',

    data() {
        return {
            customCohortInput: '',
        }
    },

    computed: {
        useCustomCohort: {
            get() { return this.$store.state.useCustomCohort },
            set(useCustomCohort) { return this.$store.dispatch('toggleUseCustomCohort', { useCustomCohort }) },
        },
        customCohort() { return this.$store.state.heatmapCustomCohort },
    },

    methods: {
        setCustomCohort() {
            this.$store.dispatch(
                'setCustomCohort', 
                { customCohort: this.customCohortInput.split('\n') }
            )
        },
    },

    mounted() { this.customCohortInput = this.customCohort.join('\n') },

}
</script>

<style>
.heatmap-custom-cohort {
    width: 100%;
}
</style>