<template>
    <div class="tab1-select-pathways">
        <pathway-db-selector />
        <pathway-selector />
        <pathways-selected />
    </div>
</template>

<script>
    import PathwayDbSelector from './PathwayDbSelector.vue'
    import PathwaySelector from './PathwaySelector.vue'
    import PathwaysSelected from './PathwaysSelected.vue'

    export default {
        name: "tab-1-select-input",
        components: { 
            PathwayDbSelector, 
            PathwaySelector,
            PathwaysSelected 
        },

        
    }
</script>

<style scoped>
.not-found-list {
    margin: 20px 0;
}

.additional-description {
    background: #ffffffaf;
    border: solid 1px black;
    max-width: 300px;
    padding: 10px;
    font-size: 0.85em;
    margin: 20px auto;
    /* margin: 20px 0; */
    /* 
    min-width: 70%;
    max-width: 70%; */
}

.input-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>