<template>
  <div class="input-sample-dashboard-percentage-selector">
    <v-switch
      v-model="percentageView"
      :label="percentageView ? 'Show as percentages' : 'Show as absolute counts'"
    ></v-switch>
  </div>
</template>

<script>
export default {
    name: "input-sample-dashboard-percentage-selector",

    computed: {
        percentageView: {
            get() { return this.$store.state.percentageView },
            set(percentageView) { return this.$store.dispatch(
                    'togglePercentageView', 
                    { percentageView }
                ) 
            },
        },
    },
}
</script>

<style>
.input-sample-dashboard-percentage-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
}
</style>