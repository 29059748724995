export default [
  {
    caseId: "C3N-00314",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00314-T",
    normal_sample_id_protein: "C3N-00314-N",
  },
  {
    caseId: "C3L-03266",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03266-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01537",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01537-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04495",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04495-T",
    normal_sample_id_protein: "C3L-04495-N",
  },
  {
    caseId: "C3N-03061",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03061-T",
    normal_sample_id_protein: "C3N-03061-N",
  },
  {
    caseId: "C3N-01719",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01719-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02164",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02164-T",
    normal_sample_id_protein: "C3L-02164-N",
  },
  {
    caseId: "C3N-00831",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00831-T",
    normal_sample_id_protein: "C3N-00831-N",
  },
  {
    caseId: "C3L-00090",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00090-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03428",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03428-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02613",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02613-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01846",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01846-T",
    normal_sample_id_protein: "C3N-01846-N",
  },
  {
    caseId: "C3L-00412",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00412-T",
    normal_sample_id_protein: "C3L-00412-N",
  },
  {
    caseId: "C3N-01368",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01368-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02186",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02186-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00104",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-00104-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02465",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02465-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04282",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-04282-T",
    normal_sample_id_protein: "C3N-04282-N",
  },
  {
    caseId: "C3L-00994",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00994-T",
    normal_sample_id_protein: "C3L-00994-N",
  },
  {
    caseId: "C3N-00303",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00303-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02714",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02714-T",
    normal_sample_id_protein: "C3N-02714-N",
  },
  {
    caseId: "C3N-00194",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00194-T",
    normal_sample_id_protein: "C3N-00194-N",
  },
  {
    caseId: "C3N-02067",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02067-T",
    normal_sample_id_protein: "C3N-02067-N",
  },
  {
    caseId: "C3N-03841",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03841-T",
    normal_sample_id_protein: "C3N-03841-N",
  },
  {
    caseId: "C3L-04473",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04473-T",
    normal_sample_id_protein: "C3L-04473-N",
  },
  {
    caseId: "C3N-02422",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02422-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02592",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02592-T",
    normal_sample_id_protein: "C3N-02592-N",
  },
  {
    caseId: "C3N-02181",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02181-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03620",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03620-T",
    normal_sample_id_protein: "C3N-03620-N",
  },
  {
    caseId: "C3L-02955",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02955-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02610",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02610-T",
    normal_sample_id_protein: "C3L-02610-N",
  },
  {
    caseId: "C3N-01381",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01381-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02256",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02256-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04853",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04853-T",
    normal_sample_id_protein: "C3L-04853-N",
  },
  {
    caseId: "C3L-03748",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03748-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03639",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03639-T",
    normal_sample_id_protein: "C3L-03639-N",
  },
  {
    caseId: "C3L-00102",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00102-T",
    normal_sample_id_protein: "C3L-00102-N",
  },
  {
    caseId: "C3L-01884",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01884-T",
    normal_sample_id_protein: "C3L-01884-N",
  },
  {
    caseId: "C3L-00622",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00622-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04119",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-04119-T",
    normal_sample_id_protein: "C3N-04119-N",
  },
  {
    caseId: "C3N-01823",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01823-T",
    normal_sample_id_protein: "C3N-01823-N",
  },
  {
    caseId: "C3L-01703",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01703-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00081",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00081-T",
    normal_sample_id_protein: "C3L-00081-N",
  },
  {
    caseId: "C3L-01156",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01156-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01505",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01505-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02998",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02998-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00999",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00999-T",
    normal_sample_id_protein: "C3L-00999-N",
  },
  {
    caseId: "C3L-00674",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-00674-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00574",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00574-T",
    normal_sample_id_protein: "C3N-00574-N",
  },
  {
    caseId: "C3L-01046",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01046-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01196",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01196-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02579",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02579-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00305",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00305-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03439",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03439-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03211",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03211-T",
    normal_sample_id_protein: "C3N-03211-N",
  },
  {
    caseId: "C3L-03407",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03407-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02549",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02549-T",
    normal_sample_id_protein: "C3L-02549-N",
  },
  {
    caseId: "C3L-02219",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02219-T",
    normal_sample_id_protein: "C3L-02219-N",
  },
  {
    caseId: "C3N-01858",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01858-T",
    normal_sample_id_protein: "C3N-01858-N",
  },
  {
    caseId: "C3L-01048",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01048-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01851",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01851-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02649",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02649-T",
    normal_sample_id_protein: "C3L-02649-N",
  },
  {
    caseId: "C3L-02041",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02041-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03747",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01154",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01154-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00294",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00294-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04071",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-04071-T",
    normal_sample_id_protein: "C3L-04071-N",
  },
  {
    caseId: "C3N-02380",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02380-T",
    normal_sample_id_protein: "C3N-02380-N",
  },
  {
    caseId: "C3N-00312",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00312-T",
    normal_sample_id_protein: "C3N-00312-N",
  },
  {
    caseId: "C3L-01328",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01328-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02183",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02183-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02606",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02606-T",
    normal_sample_id_protein: "C3L-02606-N",
  },
  {
    caseId: "C3N-02188",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02188-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01687",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01687-T",
    normal_sample_id_protein: "C3L-01687-N",
  },
  {
    caseId: "C3N-01907",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01907-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02190",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02190-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01637",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01637-T",
    normal_sample_id_protein: "C3L-01637-N",
  },
  {
    caseId: "C3N-03849",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03849-T",
    normal_sample_id_protein: "C3N-03849-N",
  },
  {
    caseId: "C3L-01662",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01662-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02426",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02426-T",
    normal_sample_id_protein: "C3N-02426-N",
  },
  {
    caseId: "C3N-02185",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02185-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01598",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01598-T",
    normal_sample_id_protein: "C3L-01598-N",
  },
  {
    caseId: "C3L-03387",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03387-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01689",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01689-T",
    normal_sample_id_protein: "C3L-01689-N",
  },
  {
    caseId: "C3L-01632",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01632-T",
    normal_sample_id_protein: "C3L-01632-N",
  },
  {
    caseId: "C3N-01856",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01856-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01167",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01167-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03350",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-03350-N",
  },
  {
    caseId: "C3N-01016",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01016-T",
    normal_sample_id_protein: "C3N-01016-N",
  },
  {
    caseId: "C3N-00832",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00832-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00729",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00729-T",
    normal_sample_id_protein: "C3N-00729-N",
  },
  {
    caseId: "C3N-01168",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01168-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01388",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01388-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00819",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00819-T",
    normal_sample_id_protein: "C3L-00819-N",
  },
  {
    caseId: "C3N-00167",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00167-T",
    normal_sample_id_protein: "C3N-00167-N",
  },
  {
    caseId: "C3N-02155",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02155-T",
    normal_sample_id_protein: "C3N-02155-N",
  },
  {
    caseId: "C3L-00604",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00604-T",
    normal_sample_id_protein: "C3L-00604-N",
  },
  {
    caseId: "C3N-00556",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00556-T",
    normal_sample_id_protein: "C3N-00556-N",
  },
  {
    caseId: "C3L-01146",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01146-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02345",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02345-T",
    normal_sample_id_protein: "C3L-02345-N",
  },
  {
    caseId: "C3N-00661",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-00661-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00598",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00598-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01061",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01061-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00415",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00415-T",
    normal_sample_id_protein: "C3L-00415-N",
  },
  {
    caseId: "C3N-00517",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00517-T",
    normal_sample_id_protein: "C3N-00517-N",
  },
  {
    caseId: "C3L-03628",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03628-T",
    normal_sample_id_protein: "C3L-03628-N",
  },
  {
    caseId: "C3L-01036",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01036-T",
    normal_sample_id_protein: "C3L-01036-N",
  },
  {
    caseId: "C3N-03666",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03666-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00518",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00518-T",
    normal_sample_id_protein: "C3N-00518-N",
  },
  {
    caseId: "C3N-01651",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01651-T",
    normal_sample_id_protein: "C3N-01651-N",
  },
  {
    caseId: "C3N-02575",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02575-T",
    normal_sample_id_protein: "C3N-02575-N",
  },
  {
    caseId: "C3L-00561",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00561-T",
    normal_sample_id_protein: "C3L-00561-N",
  },
  {
    caseId: "C3N-03487",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03487-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00607",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00607-T",
    normal_sample_id_protein: "C3L-00607-N",
  },
  {
    caseId: "C3N-02589",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02589-T",
    normal_sample_id_protein: "C3N-02589-N",
  },
  {
    caseId: "C3L-00093",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00093-T",
    normal_sample_id_protein: "C3L-00093-N",
  },
  {
    caseId: "C3N-01382",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01382-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00581",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00581-T",
    normal_sample_id_protein: "C3L-00581-N",
  },
  {
    caseId: "C3N-02944",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02944-T",
    normal_sample_id_protein: "C3N-02944-N",
  },
  {
    caseId: "C3N-03011",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03011-T",
    normal_sample_id_protein: "C3N-03011-N",
  },
  {
    caseId: "C3N-01714",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01714-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02358",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02358-T",
    normal_sample_id_protein: "C3L-02358-N",
  },
  {
    caseId: "C3L-02964",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02964-T",
    normal_sample_id_protein: "C3L-02964-N",
  },
  {
    caseId: "C3N-01645",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01645-T",
    normal_sample_id_protein: "C3N-01645-N",
  },
  {
    caseId: "C3N-01024",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01024-T",
    normal_sample_id_protein: "C3N-01024-N",
  },
  {
    caseId: "C3L-00625",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00625-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00516",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00516-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03754",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03754-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01349",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01349-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03665",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03665-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01411",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01411-T",
    normal_sample_id_protein: "C3N-01411-N",
  },
  {
    caseId: "C3N-01857",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01857-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00189",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00189-T",
    normal_sample_id_protein: "C3L-00189-N",
  },
  {
    caseId: "C3N-03839",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03839-T",
    normal_sample_id_protein: "C3N-03839-N",
  },
  {
    caseId: "C3N-01816",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01816-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03000",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03000-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00829",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00829-T",
    normal_sample_id_protein: "C3N-00829-N",
  },
  {
    caseId: "C3L-01834",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01834-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01003",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02504",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02504-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02788",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02788-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03727",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03727-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00198",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00198-T",
    normal_sample_id_protein: "C3N-00198-N",
  },
  {
    caseId: "C3N-03188",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03188-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01313",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01313-T",
    normal_sample_id_protein: "C3L-01313-N",
  },
  {
    caseId: "C3N-03884",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03884-T",
    normal_sample_id_protein: "C3N-03884-N",
  },
  {
    caseId: "C3N-04278",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04278-T",
    normal_sample_id_protein: "C3N-04278-N",
  },
  {
    caseId: "C3N-03186",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03186-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01054",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01054-T",
    normal_sample_id_protein: "C3L-01054-N",
  },
  {
    caseId: "C3N-00244",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00244-T",
    normal_sample_id_protein: "C3N-00244-N",
  },
  {
    caseId: "C3N-02785",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02785-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03848",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03848-T",
    normal_sample_id_protein: "C3N-03848-N",
  },
  {
    caseId: "C3N-01364",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01364-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01383",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01383-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02435",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02435-T",
    normal_sample_id_protein: "C3N-02435-N",
  },
  {
    caseId: "C3N-03182",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03182-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03356",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03356-T",
    normal_sample_id_protein: "C3L-03356-N",
  },
  {
    caseId: "C3N-01192",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01192-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02118",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02118-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02708",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02708-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01338",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01338-T",
    normal_sample_id_protein: "C3N-01338-N",
  },
  {
    caseId: "C3N-01900",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01900-T",
    normal_sample_id_protein: "C3N-01900-N",
  },
  {
    caseId: "C3N-01219",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01219-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03635",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03635-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01052",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01052-T",
    normal_sample_id_protein: "C3L-01052-N",
  },
  {
    caseId: "C3N-01518",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01518-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00401",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00401-T",
    normal_sample_id_protein: "C3L-00401-N",
  },
  {
    caseId: "C3L-00904",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00904-T",
    normal_sample_id_protein: "C3L-00904-N",
  },
  {
    caseId: "C3N-02770",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02770-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01040",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01040-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00249",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00249-T",
    normal_sample_id_protein: "C3N-00249-N",
  },
  {
    caseId: "C3N-02784",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02784-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03394",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03394-T",
    normal_sample_id_protein: "C3L-03394-N",
  },
  {
    caseId: "C3N-01802",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01802-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01944",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01944-T",
    normal_sample_id_protein: "C3N-01944-N",
  },
  {
    caseId: "C3N-01211",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01211-T",
    normal_sample_id_protein: "C3N-01211-N",
  },
  {
    caseId: "C3N-00200",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00200-T",
    normal_sample_id_protein: "C3N-00200-N",
  },
  {
    caseId: "C3L-00640",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00640-T",
    normal_sample_id_protein: "C3L-00640-N",
  },
  {
    caseId: "C3L-03129",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03129-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01011",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01011-T",
    normal_sample_id_protein: "C3N-01011-N",
  },
  {
    caseId: "C3L-01286",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01286-T",
    normal_sample_id_protein: "C3L-01286-N",
  },
  {
    caseId: "C3N-01379",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01379-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01149",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01149-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00846",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00846-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02782",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02782-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01285",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01285-T",
    normal_sample_id_protein: "C3L-01285-N",
  },
  {
    caseId: "C3L-01275",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01275-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00511",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00511-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00514",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00514-T",
    normal_sample_id_protein: "C3N-00514-N",
  },
  {
    caseId: "C3N-00436",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00436-T",
    normal_sample_id_protein: "C3N-00436-N",
  },
  {
    caseId: "C3L-00094",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00094-T",
    normal_sample_id_protein: "C3L-00094-N",
  },
  {
    caseId: "C3L-03390",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03390-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02729",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02729-T",
    normal_sample_id_protein: "C3N-02729-N",
  },
  {
    caseId: "C3L-04844",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-04844-T",
    normal_sample_id_protein: "C3L-04844-N",
  },
  {
    caseId: "C3N-02769",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02769-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00928",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00928-T",
    normal_sample_id_protein: "C3L-00928-N",
  },
  {
    caseId: "C3L-02115",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02115-T",
    normal_sample_id_protein: "C3L-02115-N",
  },
  {
    caseId: "C3L-01683",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01683-T",
    normal_sample_id_protein: "C3L-01683-N",
  },
  {
    caseId: "C3L-02116",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02116-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03783",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03783-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02675",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02675-T",
    normal_sample_id_protein: "C3N-02675-N",
  },
  {
    caseId: "C3N-01166",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01166-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00448",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00448-T",
    normal_sample_id_protein: "C3L-00448-N",
  },
  {
    caseId: "C3L-00606",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00606-T",
    normal_sample_id_protein: "C3L-00606-N",
  },
  {
    caseId: "C3N-03670",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03670-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00977",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00977-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04475",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04475-T",
    normal_sample_id_protein: "C3L-04475-N",
  },
  {
    caseId: "C3L-00103",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00103-T",
    normal_sample_id_protein: "C3L-00103-N",
  },
  {
    caseId: "C3L-02951",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02951-T",
    normal_sample_id_protein: "C3L-02951-N",
  },
  {
    caseId: "C3N-00959",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00959-T",
    normal_sample_id_protein: "C3N-00959-N",
  },
  {
    caseId: "C3L-01281",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01281-T",
    normal_sample_id_protein: "C3L-01281-N",
  },
  {
    caseId: "C3L-02890",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02890-T",
    normal_sample_id_protein: "C3L-02890-N",
  },
  {
    caseId: "C3N-01517",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01517-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04479",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04479-T",
    normal_sample_id_protein: "C3L-04479-N",
  },
  {
    caseId: "C3L-00356",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00356-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00080",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00080-T",
    normal_sample_id_protein: "C3L-00080-N",
  },
  {
    caseId: "C3L-02349",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02349-T",
    normal_sample_id_protein: "C3L-02349-N",
  },
  {
    caseId: "C3L-00918",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00918-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00599",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00599-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04276",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04276-T",
    normal_sample_id_protein: "C3N-04276-N",
  },
  {
    caseId: "C3L-00908",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00908-T",
    normal_sample_id_protein: "C3L-00908-N",
  },
  {
    caseId: "C3L-01663",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01663-T",
    normal_sample_id_protein: "C3L-01663-N",
  },
  {
    caseId: "C3N-00665",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-00665-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01247",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01247-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00157",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00157-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00161",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00161-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00359",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00359-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01288",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01288-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01887",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01887-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00313",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00313-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00246",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00246-T",
    normal_sample_id_protein: "C3N-00246-N",
  },
  {
    caseId: "C3L-00422",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00422-T",
    normal_sample_id_protein: "C3L-00422-N",
  },
  {
    caseId: "C3N-00315",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00315-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00293",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00293-T",
    normal_sample_id_protein: "C3N-00293-N",
  },
  {
    caseId: "C3L-01304",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01304-T",
    normal_sample_id_protein: "C3L-01304-N",
  },
  {
    caseId: "C3N-00320",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00320-T",
    normal_sample_id_protein: "C3N-00320-N",
  },
  {
    caseId: "C3N-00497",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-00497-T",
    normal_sample_id_protein: "C3N-00497-N",
  },
  {
    caseId: "C3L-00001",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00001-T",
    normal_sample_id_protein: "C3L-00001-N",
  },
  {
    caseId: "C3L-00965",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00965-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01180",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01180-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01179",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01179-T",
    normal_sample_id_protein: "C3N-01179-N",
  },
  {
    caseId: "C3N-01361",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01361-T",
    normal_sample_id_protein: "C3N-01361-N",
  },
  {
    caseId: "C3L-00140",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00140-T",
    normal_sample_id_protein: "C3L-00140-N",
  },
  {
    caseId: "C3N-00492",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00492-T",
    normal_sample_id_protein: "C3N-00492-N",
  },
  {
    caseId: "C3L-00586",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00586-T",
    normal_sample_id_protein: "C3L-00586-N",
  },
  {
    caseId: "C3L-01889",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01889-T",
    normal_sample_id_protein: "C3L-01889-N",
  },
  {
    caseId: "C3L-00145",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00145-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00910",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00910-T",
    normal_sample_id_protein: "C3L-00910-N",
  },
  {
    caseId: "C3L-00907",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00907-T",
    normal_sample_id_protein: "C3L-00907-N",
  },
  {
    caseId: "C3L-00605",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00605-T",
    normal_sample_id_protein: "C3L-00605-N",
  },
  {
    caseId: "C3L-01607",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01607-T",
    normal_sample_id_protein: "C3L-01607-N",
  },
  {
    caseId: "C3L-00603",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00603-T",
    normal_sample_id_protein: "C3L-00603-N",
  },
  {
    caseId: "C3L-00095",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00095-T",
    normal_sample_id_protein: "C3L-00095-N",
  },
  {
    caseId: "C3L-00136",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00136-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00242",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00242-T",
    normal_sample_id_protein: "C3N-00242-N",
  },
  {
    caseId: "C3N-00494",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00494-T",
    normal_sample_id_protein: "C3N-00494-N",
  },
  {
    caseId: "C3L-00923",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00923-T",
    normal_sample_id_protein: "C3L-00923-N",
  },
  {
    caseId: "C3L-00902",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00902-T",
    normal_sample_id_protein: "C3L-00902-N",
  },
  {
    caseId: "C3L-00083",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00083-T",
    normal_sample_id_protein: "C3L-00083-N",
  },
  {
    caseId: "C3N-00495",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00495-T",
    normal_sample_id_protein: "C3N-00495-N",
  },
  {
    caseId: "C3N-00953",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00953-T",
    normal_sample_id_protein: "C3N-00953-N",
  },
  {
    caseId: "C3L-01311",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01311-T",
    normal_sample_id_protein: "C3L-01311-N",
  },
  {
    caseId: "C3L-00563",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00563-T",
    normal_sample_id_protein: "C3L-00563-N",
  },
  {
    caseId: "C3L-01282",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01282-T",
    normal_sample_id_protein: "C3L-01282-N",
  },
  {
    caseId: "C3L-01312",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01312-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00917",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00917-T",
    normal_sample_id_protein: "C3L-00917-N",
  },
  {
    caseId: "C3L-01606",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01606-T",
    normal_sample_id_protein: "C3L-01606-N",
  },
  {
    caseId: "C3L-00360",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00360-T",
    normal_sample_id_protein: "C3L-00360-N",
  },
  {
    caseId: "C3L-00927",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00927-T",
    normal_sample_id_protein: "C3L-00927-N",
  },
  {
    caseId: "C3L-00921",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00921-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00156",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00156-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01603",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01603-T",
    normal_sample_id_protein: "C3L-01603-N",
  },
  {
    caseId: "C3N-00834",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00834-T",
    normal_sample_id_protein: "C3N-00834-N",
  },
  {
    caseId: "C3L-00026",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00026-T",
    normal_sample_id_protein: "C3L-00026-N",
  },
  {
    caseId: "C3N-00880",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00880-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00413",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00413-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01861",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01861-T",
    normal_sample_id_protein: "C3L-01861-N",
  },
  {
    caseId: "C3N-00825",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00825-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01194",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01194-T",
    normal_sample_id_protein: "C3N-01194-N",
  },
  {
    caseId: "C3L-00905",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00905-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00589",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00589-T",
    normal_sample_id_protein: "C3L-00589-N",
  },
  {
    caseId: "C3L-00449",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00449-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00098",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00098-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00362",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00362-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00297",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00297-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00097",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00097-T",
    normal_sample_id_protein: "C3L-00097-N",
  },
  {
    caseId: "C3N-01175",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01175-T",
    normal_sample_id_protein: "C3N-01175-N",
  },
  {
    caseId: "C3N-01176",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01176-T",
    normal_sample_id_protein: "C3N-01176-N",
  },
  {
    caseId: "C3L-00601",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00601-T",
    normal_sample_id_protein: "C3L-00601-N",
  },
  {
    caseId: "C3N-01178",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01178-T",
    normal_sample_id_protein: "C3N-01178-N",
  },
  {
    caseId: "C3N-00498",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00498-T",
    normal_sample_id_protein: "C3N-00498-N",
  },
  {
    caseId: "C3L-00418",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00418-T",
    normal_sample_id_protein: "C3L-00418-N",
  },
  {
    caseId: "C3L-00032",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00032-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01755",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01755-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00295",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00295-T",
    normal_sample_id_protein: "C3N-00295-N",
  },
  {
    caseId: "C3L-00143",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00143-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01838",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01838-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00361",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00361-T",
    normal_sample_id_protein: "C3L-00361-N",
  },
  {
    caseId: "C3N-01502",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01502-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00199",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00199-T",
    normal_sample_id_protein: "C3N-00199-N",
  },
  {
    caseId: "C3N-00437",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00437-T",
    normal_sample_id_protein: "C3N-00437-N",
  },
  {
    caseId: "C3N-00310",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00310-T",
    normal_sample_id_protein: "C3N-00310-N",
  },
  {
    caseId: "C3L-01330",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01330-T",
    normal_sample_id_protein: "C3L-01330-N",
  },
  {
    caseId: "C3N-01756",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01756-T",
    normal_sample_id_protein: "C3N-01756-N",
  },
  {
    caseId: "C3L-01682",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01682-T",
    normal_sample_id_protein: "C3L-01682-N",
  },
  {
    caseId: "C3L-01252",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01252-T",
    normal_sample_id_protein: "C3L-01252-N",
  },
  {
    caseId: "C3N-01754",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01754-T",
    normal_sample_id_protein: "C3N-01754-N",
  },
  {
    caseId: "C3N-01799",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01799-T",
    normal_sample_id_protein: "C3N-01799-N",
  },
  {
    caseId: "C3N-02284",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02284-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00938",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00938-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02285",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02285-T",
    normal_sample_id_protein: "C3N-02285-N",
  },
  {
    caseId: "C3L-01246",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01246-T",
    normal_sample_id_protein: "C3L-01246-N",
  },
  {
    caseId: "C3N-00247",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-00247-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00781",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00781-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01253",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01253-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03123",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03123-T",
    normal_sample_id_protein: "C3L-03123-N",
  },
  {
    caseId: "C3N-02300",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02300-T",
    normal_sample_id_protein: "C3N-02300-N",
  },
  {
    caseId: "C3N-03015",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03015-T",
    normal_sample_id_protein: "C3N-03015-N",
  },
  {
    caseId: "C3L-00949",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00949-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02984",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02984-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01646",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01646-T",
    normal_sample_id_protein: "C3N-01646-N",
  },
  {
    caseId: "C3L-00770",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00770-T",
    normal_sample_id_protein: "C3L-00770-N",
  },
  {
    caseId: "C3L-00932",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00932-T",
    normal_sample_id_protein: "C3L-00932-N",
  },
  {
    caseId: "C3L-00610",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00610-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01249",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01249-T",
    normal_sample_id_protein: "C3L-01249-N",
  },
  {
    caseId: "C3N-00733",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00733-T",
    normal_sample_id_protein: "C3N-00733-N",
  },
  {
    caseId: "C3N-02288",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02288-T",
    normal_sample_id_protein: "C3N-02288-N",
  },
  {
    caseId: "C3L-00769",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00769-T",
    normal_sample_id_protein: "C3L-00769-N",
  },
  {
    caseId: "C3L-02127",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02127-T",
    normal_sample_id_protein: "C3L-02127-N",
  },
  {
    caseId: "C3L-02963",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02963-T",
    normal_sample_id_protein: "C3L-02963-N",
  },
  {
    caseId: "C3L-02546",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02546-T",
    normal_sample_id_protein: "C3L-02546-N",
  },
  {
    caseId: "C3N-01648",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01648-T",
    normal_sample_id_protein: "C3N-01648-N",
  },
  {
    caseId: "C3N-01649",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01649-T",
    normal_sample_id_protein: "C3N-01649-N",
  },
  {
    caseId: "C3N-03456",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03456-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03965",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03965-T",
    normal_sample_id_protein: "C3L-03965-N",
  },
  {
    caseId: "C3L-02969",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02969-T",
    normal_sample_id_protein: "C3L-02969-N",
  },
  {
    caseId: "C3L-02348",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02348-T",
    normal_sample_id_protein: "C3L-02348-N",
  },
  {
    caseId: "C3L-02650",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02650-T",
    normal_sample_id_protein: "C3L-02650-N",
  },
  {
    caseId: "C3L-00792",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00792-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02350",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02350-T",
    normal_sample_id_protein: "C3L-02350-N",
  },
  {
    caseId: "C3L-02627",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02627-T",
    normal_sample_id_protein: "C3L-02627-N",
  },
  {
    caseId: "C3L-00765",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00765-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00973",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00973-T",
    normal_sample_id_protein: "C3L-00973-N",
  },
  {
    caseId: "C3L-02168",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02168-T",
    normal_sample_id_protein: "C3L-02168-N",
  },
  {
    caseId: "C3L-02891",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02891-T",
    normal_sample_id_protein: "C3L-02891-N",
  },
  {
    caseId: "C3N-02289",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02289-T",
    normal_sample_id_protein: "C3N-02289-N",
  },
  {
    caseId: "C3L-02130",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02130-T",
    normal_sample_id_protein: "C3L-02130-N",
  },
  {
    caseId: "C3N-03457",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03457-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03458",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03458-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01340",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01340-T",
    normal_sample_id_protein: "C3N-01340-N",
  },
  {
    caseId: "C3N-02434",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02434-T",
    normal_sample_id_protein: "C3N-02434-N",
  },
  {
    caseId: "C3N-03013",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03013-T",
    normal_sample_id_protein: "C3N-03013-N",
  },
  {
    caseId: "C3L-00947",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00947-T",
    normal_sample_id_protein: "C3L-00947-N",
  },
  {
    caseId: "C3L-02552",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02552-T",
    normal_sample_id_protein: "C3L-02552-N",
  },
  {
    caseId: "C3L-01000",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01000-T",
    normal_sample_id_protein: "C3L-01000-N",
  },
  {
    caseId: "C3L-02968",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02968-T",
    normal_sample_id_protein: "C3L-02968-N",
  },
  {
    caseId: "C3N-02275",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02275-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01256",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01256-T",
    normal_sample_id_protein: "C3L-01256-N",
  },
  {
    caseId: "C3N-01346",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01346-T",
    normal_sample_id_protein: "C3N-01346-N",
  },
  {
    caseId: "C3L-00817",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00817-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02112",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02112-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01012",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01012-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02693",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3N-02693-N",
  },
  {
    caseId: "C3N-01946",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01946-T",
    normal_sample_id_protein: "C3N-01946-N",
  },
  {
    caseId: "C3N-01757",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01757-T",
    normal_sample_id_protein: "C3N-01757-N",
  },
  {
    caseId: "C3N-03425",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03425-T",
    normal_sample_id_protein: "C3N-03425-N",
  },
  {
    caseId: "C3N-03424",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03424-T",
    normal_sample_id_protein: "C3N-03424-N",
  },
  {
    caseId: "C3N-03441",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03441-T",
    normal_sample_id_protein: "C3N-03441-N",
  },
  {
    caseId: "C3L-01925",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01925-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03012",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03012-T",
    normal_sample_id_protein: "C3N-03012-N",
  },
  {
    caseId: "C3L-00942",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00942-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00961",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00961-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03781",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03781-T",
    normal_sample_id_protein: "C3N-03781-N",
  },
  {
    caseId: "C3N-01620",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01620-T",
    normal_sample_id_protein: "C3N-01620-N",
  },
  {
    caseId: "C3N-01947",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01947-T",
    normal_sample_id_protein: "C3N-01947-N",
  },
  {
    caseId: "C3N-02375",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02375-T",
    normal_sample_id_protein: "C3N-02375-N",
  },
  {
    caseId: "C3N-02421",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02421-T",
    normal_sample_id_protein: "C3N-02421-N",
  },
  {
    caseId: "C3L-03378",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-03378-T",
    normal_sample_id_protein: "C3L-03378-N",
  },
  {
    caseId: "C3N-00957",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00957-T",
    normal_sample_id_protein: "C3N-00957-N",
  },
  {
    caseId: "C3N-04277",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04277-T",
    normal_sample_id_protein: "C3N-04277-N",
  },
  {
    caseId: "C3N-03785",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03785-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00263",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00263-T",
    normal_sample_id_protein: "C3L-00263-N",
  },
  {
    caseId: "C3L-04391",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-04391-T",
    normal_sample_id_protein: "C3L-04391-N",
  },
  {
    caseId: "C3N-02433",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02433-T",
    normal_sample_id_protein: "C3N-02433-N",
  },
  {
    caseId: "C3N-00317",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00317-T",
    normal_sample_id_protein: "C3N-00317-N",
  },
  {
    caseId: "C3N-02572",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02572-T",
    normal_sample_id_protein: "C3N-02572-N",
  },
  {
    caseId: "C3N-04279",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04279-T",
    normal_sample_id_protein: "C3N-04279-N",
  },
  {
    caseId: "C3N-04275",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04275-T",
    normal_sample_id_protein: "C3N-04275-N",
  },
  {
    caseId: "C3L-01924",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01924-T",
    normal_sample_id_protein: "C3L-01924-N",
  },
  {
    caseId: "C3N-00323",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00323-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01836",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01836-T",
    normal_sample_id_protein: "C3L-01836-N",
  },
  {
    caseId: "C3N-01945",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01945-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02587",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02587-T",
    normal_sample_id_protein: "C3N-02587-N",
  },
  {
    caseId: "C3N-02588",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02588-T",
    normal_sample_id_protein: "C3N-02588-N",
  },
  {
    caseId: "C3L-03632",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03632-T",
    normal_sample_id_protein: "C3L-03632-N",
  },
  {
    caseId: "C3N-00734",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00734-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03782",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03782-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02000",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02000-T",
    normal_sample_id_protein: "C3N-02000-N",
  },
  {
    caseId: "C3N-04280",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04280-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02786",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02786-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02425",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02425-T",
    normal_sample_id_protein: "C3N-02425-N",
  },
  {
    caseId: "C3N-02374",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02374-T",
    normal_sample_id_protein: "C3N-02374-N",
  },
  {
    caseId: "C3N-01859",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01859-T",
    normal_sample_id_protein: "C3N-01859-N",
  },
  {
    caseId: "C3N-02585",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02585-T",
    normal_sample_id_protein: "C3N-02585-N",
  },
  {
    caseId: "C3L-02900",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02900-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01510",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01510-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03744",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03744-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01997",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01997-T",
    normal_sample_id_protein: "C3N-01997-N",
  },
  {
    caseId: "C3L-02646",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02646-T",
    normal_sample_id_protein: "C3L-02646-N",
  },
  {
    caseId: "C3L-00780",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00780-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02925",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02925-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04354",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-04354-T",
    normal_sample_id_protein: "C3L-04354-N",
  },
  {
    caseId: "C3N-00512",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00512-T",
    normal_sample_id_protein: "C3N-00512-N",
  },
  {
    caseId: "C3N-02940",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02940-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03875",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03875-T",
    normal_sample_id_protein: "C3N-03875-N",
  },
  {
    caseId: "C3N-03028",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03028-T",
    normal_sample_id_protein: "C3N-03028-N",
  },
  {
    caseId: "C3L-02508",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02508-T",
    normal_sample_id_protein: "C3L-02508-N",
  },
  {
    caseId: "C3N-01752",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01752-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03963",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03963-T",
    normal_sample_id_protein: "C3L-03963-N",
  },
  {
    caseId: "C3L-03968",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03968-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02783",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02783-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01157",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01157-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00822",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00822-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02617",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-02617-T",
    normal_sample_id_protein: "C3L-02617-N",
  },
  {
    caseId: "C3N-04124",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-04124-T",
    normal_sample_id_protein: "C3N-04124-N",
  },
  {
    caseId: "C3L-00946",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00946-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03433",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03433-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00828",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00828-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01744",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01744-T",
    normal_sample_id_protein: "C3L-01744-N",
  },
  {
    caseId: "C3N-01892",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01892-T",
    normal_sample_id_protein: "C3N-01892-N",
  },
  {
    caseId: "C3N-02582",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02582-T",
    normal_sample_id_protein: "C3N-02582-N",
  },
  {
    caseId: "C3L-00008",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00010",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00010-T",
    normal_sample_id_protein: "C3L-00010-N",
  },
  {
    caseId: "C3N-02529",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02529-T",
    normal_sample_id_protein: "C3N-02529-N",
  },
  {
    caseId: "C3N-01943",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01943-T",
    normal_sample_id_protein: "C3N-01943-N",
  },
  {
    caseId: "C3N-00177",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00177-T",
    normal_sample_id_protein: "C3N-00177-N",
  },
  {
    caseId: "C3N-03204",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03204-T",
    normal_sample_id_protein: "C3N-03204-N",
  },
  {
    caseId: "C3L-02642",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02642-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00168",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00168-T",
    normal_sample_id_protein: "C3N-00168-N",
  },
  {
    caseId: "C3L-00137",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00137-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03400",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03400-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02669",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02669-T",
    normal_sample_id_protein: "C3L-02669-N",
  },
  {
    caseId: "C3N-00549",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00549-T",
    normal_sample_id_protein: "C3N-00549-N",
  },
  {
    caseId: "C3N-00555",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-00555-T",
    normal_sample_id_protein: "C3N-00555-N",
  },
  {
    caseId: "C3N-01808",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01808-T",
    normal_sample_id_protein: "C3N-01808-N",
  },
  {
    caseId: "C3N-04127",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-04127-T",
    normal_sample_id_protein: "C3N-04127-N",
  },
  {
    caseId: "C3L-01248",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01248-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00963",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00963-T",
    normal_sample_id_protein: "C3L-00963-N",
  },
  {
    caseId: "C3N-00858",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00858-T",
    normal_sample_id_protein: "C3N-00858-N",
  },
  {
    caseId: "C3L-02463",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02463-T",
    normal_sample_id_protein: "C3L-02463-N",
  },
  {
    caseId: "C3N-03086",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03086-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00006",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00006-T",
    normal_sample_id_protein: "C3L-00006-N",
  },
  {
    caseId: "C3N-03880",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03880-T",
    normal_sample_id_protein: "C3N-03880-N",
  },
  {
    caseId: "C3L-01283",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01283-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02333",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02333-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03051",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03051-T",
    normal_sample_id_protein: "C3N-03051-N",
  },
  {
    caseId: "C3L-03743",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03743-T",
    normal_sample_id_protein: "C3L-03743-N",
  },
  {
    caseId: "C3N-01758",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01758-T",
    normal_sample_id_protein: "C3N-01758-N",
  },
  {
    caseId: "C3N-03006",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02163",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02163-T",
    normal_sample_id_protein: "C3L-02163-N",
  },
  {
    caseId: "C3N-03933",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03933-T",
    normal_sample_id_protein: "C3N-03933-N",
  },
  {
    caseId: "C3N-03664",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03664-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02713",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02713-T",
    normal_sample_id_protein: "C3N-02713-N",
  },
  {
    caseId: "C3N-03882",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03882-T",
    normal_sample_id_protein: "C3N-03882-N",
  },
  {
    caseId: "C3L-00791",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00791-T",
    normal_sample_id_protein: "C3L-00791-N",
  },
  {
    caseId: "C3N-03226",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03226-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03837",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03837-T",
    normal_sample_id_protein: "C3N-03837-N",
  },
  {
    caseId: "C3N-03840",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03840-T",
    normal_sample_id_protein: "C3N-03840-N",
  },
  {
    caseId: "C3N-02423",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02423-T",
    normal_sample_id_protein: "C3N-02423-N",
  },
  {
    caseId: "C3N-00339",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00339-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01524",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01524-T",
    normal_sample_id_protein: "C3N-01524-N",
  },
  {
    caseId: "C3N-00340",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00340-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01522",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01522-T",
    normal_sample_id_protein: "C3N-01522-N",
  },
  {
    caseId: "C3L-00088",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00088-T",
    normal_sample_id_protein: "C3L-00088-N",
  },
  {
    caseId: "C3N-03928",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03928-T",
    normal_sample_id_protein: "C3N-03928-N",
  },
  {
    caseId: "C3N-00390",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00390-T",
    normal_sample_id_protein: "C3N-00390-N",
  },
  {
    caseId: "C3N-01261",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01261-T",
    normal_sample_id_protein: "C3N-01261-N",
  },
  {
    caseId: "C3N-03877",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03877-T",
    normal_sample_id_protein: "C3N-03877-N",
  },
  {
    caseId: "C3N-01217",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01217-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03889",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03889-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01842",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01842-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01521",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01521-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00383",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00383-T",
    normal_sample_id_protein: "C3N-00383-N",
  },
  {
    caseId: "C3N-03888",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03888-T",
    normal_sample_id_protein: "C3N-03888-N",
  },
  {
    caseId: "C3N-02727",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02727-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03488",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03488-T",
    normal_sample_id_protein: "C3N-03488-N",
  },
  {
    caseId: "C3L-00447",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00447-T",
    normal_sample_id_protein: "C3L-00447-N",
  },
  {
    caseId: "C3N-01893",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01893-T",
    normal_sample_id_protein: "C3N-01893-N",
  },
  {
    caseId: "C3L-01890",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01890-T",
    normal_sample_id_protein: "C3L-01890-N",
  },
  {
    caseId: "C3N-00380",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00380-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00377",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00377-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03612",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03612-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00217",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00217-T",
    normal_sample_id_protein: "C3N-00217-N",
  },
  {
    caseId: "C3N-00322",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00322-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00337",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00337-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00335",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00335-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01212",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01212-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00389",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00389-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00386",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00386-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01825",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01825-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01214",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01214-T",
    normal_sample_id_protein: "C3N-01214-N",
  },
  {
    caseId: "C3N-00334",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00334-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01213",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01213-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00306",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00306-T",
    normal_sample_id_protein: "C3N-00306-N",
  },
  {
    caseId: "C3N-03009",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00333",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00333-T",
    normal_sample_id_protein: "C3N-00333-N",
  },
  {
    caseId: "C3N-00388",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00388-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03878",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03878-T",
    normal_sample_id_protein: "C3N-03878-N",
  },
  {
    caseId: "C3L-01327",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01327-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01267",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01267-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00324",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00324-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00545",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00545-T",
    normal_sample_id_protein: "C3N-00545-N",
  },
  {
    caseId: "C3N-00326",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00326-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00328",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00328-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01220",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01220-T",
    normal_sample_id_protein: "C3N-01220-N",
  },
  {
    caseId: "C3N-03851",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03851-T",
    normal_sample_id_protein: "C3N-03851-N",
  },
  {
    caseId: "C3N-00836",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00836-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00379",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00379-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04152",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04152-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02695",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02695-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00857",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00857-T",
    normal_sample_id_protein: "C3N-00857-N",
  },
  {
    caseId: "C3N-00848",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00848-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02365",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-02365-T",
    normal_sample_id_protein: "C3L-02365-N",
  },
  {
    caseId: "C3N-03490",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03490-T",
    normal_sample_id_protein: "C3N-03490-N",
  },
  {
    caseId: "C3L-01287",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01287-T",
    normal_sample_id_protein: "C3L-01287-N",
  },
  {
    caseId: "C3N-01337",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01337-T",
    normal_sample_id_protein: "C3N-01337-N",
  },
  {
    caseId: "C3N-00852",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00852-T",
    normal_sample_id_protein: "C3N-00852-N",
  },
  {
    caseId: "C3N-00850",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00850-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00847",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00847-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02694",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02694-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00277",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00277-T",
    normal_sample_id_protein: "C3L-00277-N",
  },
  {
    caseId: "C3L-00416",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00416-T",
    normal_sample_id_protein: "C3L-00416-N",
  },
  {
    caseId: "C3L-00279",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00279-T",
    normal_sample_id_protein: "C3L-00279-N",
  },
  {
    caseId: "C3N-01001",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01862",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-01862-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01798",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01798-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00743",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00743-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00009",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00009-T",
    normal_sample_id_protein: "C3L-00009-N",
  },
  {
    caseId: "C3L-01257",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01257-T",
    normal_sample_id_protein: "C3L-01257-N",
  },
  {
    caseId: "C3L-00144",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00144-T",
    normal_sample_id_protein: "C3L-00144-N",
  },
  {
    caseId: "C3L-03371",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03371-T",
    normal_sample_id_protein: "C3L-03371-N",
  },
  {
    caseId: "C3N-00221",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-00221-T",
    normal_sample_id_protein: "C3N-00221-N",
  },
  {
    caseId: "C3N-00866",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00866-T",
    normal_sample_id_protein: "C3N-00866-N",
  },
  {
    caseId: "C3N-03180",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03180-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02619",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02619-T",
    normal_sample_id_protein: "C3L-02619-N",
  },
  {
    caseId: "C3L-02625",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02625-T",
    normal_sample_id_protein: "C3L-02625-N",
  },
  {
    caseId: "C3L-00799",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00799-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03678",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03678-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02573",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02573-T",
    normal_sample_id_protein: "C3N-02573-N",
  },
  {
    caseId: "C3L-04025",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-04025-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00579",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00579-T",
    normal_sample_id_protein: "C3N-00579-N",
  },
  {
    caseId: "C3N-03876",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03876-T",
    normal_sample_id_protein: "C3N-03876-N",
  },
  {
    caseId: "C3N-00547",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00547-T",
    normal_sample_id_protein: "C3N-00547-N",
  },
  {
    caseId: "C3L-04014",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-04014-T",
    normal_sample_id_protein: "C3L-04014-N",
  },
  {
    caseId: "C3N-01017",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01017-T",
    normal_sample_id_protein: "C3N-01017-N",
  },
  {
    caseId: "C3N-02379",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02379-T",
    normal_sample_id_protein: "C3N-02379-N",
  },
  {
    caseId: "C3N-01414",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01414-T",
    normal_sample_id_protein: "C3N-01414-N",
  },
  {
    caseId: "C3N-02010",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02010-T",
    normal_sample_id_protein: "C3N-02010-N",
  },
  {
    caseId: "C3L-00997",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00997-T",
    normal_sample_id_protein: "C3L-00997-N",
  },
  {
    caseId: "C3N-01413",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01413-T",
    normal_sample_id_protein: "C3N-01413-N",
  },
  {
    caseId: "C3N-01030",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01030-T",
    normal_sample_id_protein: "C3N-01030-N",
  },
  {
    caseId: "C3N-01072",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01072-T",
    normal_sample_id_protein: "C3N-01072-N",
  },
  {
    caseId: "C3L-00790",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00790-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11LU016",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "11LU016-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01410",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01410-T",
    normal_sample_id_protein: "C3N-01410-N",
  },
  {
    caseId: "C3N-02768",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02768-T",
    normal_sample_id_protein: "C3N-02768-N",
  },
  {
    caseId: "C3N-01948",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01948-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01416",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01416-T",
    normal_sample_id_protein: "C3N-01416-N",
  },
  {
    caseId: "C3L-00987",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00987-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04273",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04273-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00572",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00572-T",
    normal_sample_id_protein: "C3N-00572-N",
  },
  {
    caseId: "C3N-01021",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01021-T",
    normal_sample_id_protein: "C3N-01021-N",
  },
  {
    caseId: "C3N-01489",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01489-T",
    normal_sample_id_protein: "C3N-01489-N",
  },
  {
    caseId: "C3L-00017",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02279",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02279-T",
    normal_sample_id_protein: "C3N-02279-N",
  },
  {
    caseId: "C3L-00139",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00139-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01415",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01415-T",
    normal_sample_id_protein: "C3N-01415-N",
  },
  {
    caseId: "C3N-01405",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01405-T",
    normal_sample_id_protein: "C3N-01405-N",
  },
  {
    caseId: "C3N-02158",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02158-T",
    normal_sample_id_protein: "C3N-02158-N",
  },
  {
    caseId: "C3N-01380",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01380-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01023",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01023-T",
    normal_sample_id_protein: "C3N-01023-N",
  },
  {
    caseId: "C3N-00871",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00871-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00513",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00513-T",
    normal_sample_id_protein: "C3N-00513-N",
  },
  {
    caseId: "C3N-01488",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01488-T",
    normal_sample_id_protein: "C3N-01488-N",
  },
  {
    caseId: "C3N-02149",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02149-T",
    normal_sample_id_protein: "C3N-02149-N",
  },
  {
    caseId: "C3N-03190",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03190-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02145",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02145-T",
    normal_sample_id_protein: "C3N-02145-N",
  },
  {
    caseId: "C3N-02089",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02089-T",
    normal_sample_id_protein: "C3N-02089-N",
  },
  {
    caseId: "C3N-00559",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00559-T",
    normal_sample_id_protein: "C3N-00559-N",
  },
  {
    caseId: "C3N-03853",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03853-T",
    normal_sample_id_protein: "C3N-03853-N",
  },
  {
    caseId: "C3N-00577",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00577-T",
    normal_sample_id_protein: "C3N-00577-N",
  },
  {
    caseId: "C3N-01020",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01020-T",
    normal_sample_id_protein: "C3N-01020-N",
  },
  {
    caseId: "C3N-00552",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00552-T",
    normal_sample_id_protein: "C3N-00552-N",
  },
  {
    caseId: "C3N-00550",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00550-T",
    normal_sample_id_protein: "C3N-00550-N",
  },
  {
    caseId: "C3N-03184",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03184-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00551",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00551-T",
    normal_sample_id_protein: "C3N-00551-N",
  },
  {
    caseId: "C3N-01814",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01814-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00580",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00580-T",
    normal_sample_id_protein: "C3N-00580-N",
  },
  {
    caseId: "C3L-00004",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00004-T",
    normal_sample_id_protein: "C3L-00004-N",
  },
  {
    caseId: "C3L-01553",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01553-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00737",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00737-T",
    normal_sample_id_protein: "C3N-00737-N",
  },
  {
    caseId: "C3L-02970",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02970-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00560",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00560-T",
    normal_sample_id_protein: "C3N-00560-N",
  },
  {
    caseId: "C3L-01124",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01124-T",
    normal_sample_id_protein: "C3L-01124-N",
  },
  {
    caseId: "C3N-00169",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00169-T",
    normal_sample_id_protein: "C3N-00169-N",
  },
  {
    caseId: "C3L-01031",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01031-T",
    normal_sample_id_protein: "C3L-01031-N",
  },
  {
    caseId: "C3N-00546",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00546-T",
    normal_sample_id_protein: "C3N-00546-N",
  },
  {
    caseId: "C3L-01155",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01155-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02295",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02295-T",
    normal_sample_id_protein: "C3N-02295-N",
  },
  {
    caseId: "C3N-02730",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02730-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02424",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02424-T",
    normal_sample_id_protein: "C3N-02424-N",
  },
  {
    caseId: "C3L-02660",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02660-T",
    normal_sample_id_protein: "C3L-02660-N",
  },
  {
    caseId: "C3L-01138",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-01138-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04162",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-04162-T",
    normal_sample_id_protein: "C3N-04162-N",
  },
  {
    caseId: "C3N-02002",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02002-T",
    normal_sample_id_protein: "C3N-02002-N",
  },
  {
    caseId: "C3N-02003",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02003-T",
    normal_sample_id_protein: "C3N-02003-N",
  },
  {
    caseId: "C3L-01284",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04126",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-04126-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01882",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01882-T",
    normal_sample_id_protein: "C3L-01882-N",
  },
  {
    caseId: "C3N-00180",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00180-T",
    normal_sample_id_protein: "C3N-00180-N",
  },
  {
    caseId: "C3L-00800",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00800-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01885",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01885-T",
    normal_sample_id_protein: "C3L-01885-N",
  },
  {
    caseId: "C3N-01716",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01716-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01028",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01028-T",
    normal_sample_id_protein: "C3N-01028-N",
  },
  {
    caseId: "C3N-03070",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03070-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00084",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00796",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00796-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03076",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03076-T",
    normal_sample_id_protein: "C3N-03076-N",
  },
  {
    caseId: "C3N-00302",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00302-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01557",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01557-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02621",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-02621-T",
    normal_sample_id_protein: "C3L-02621-N",
  },
  {
    caseId: "C3N-03072",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03072-T",
    normal_sample_id_protein: "C3N-03072-N",
  },
  {
    caseId: "C3L-00365",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-00365-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03183",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03183-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03486",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03486-T",
    normal_sample_id_protein: "C3N-03486-N",
  },
  {
    caseId: "C3N-03440",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03440-T",
    normal_sample_id_protein: "C3N-03440-N",
  },
  {
    caseId: "C3L-04849",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-04849-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03886",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03886-T",
    normal_sample_id_protein: "C3N-03886-N",
  },
  {
    caseId: "C3L-01037",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01037-T",
    normal_sample_id_protein: "C3L-01037-N",
  },
  {
    caseId: "C3L-00583",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00583-T",
    normal_sample_id_protein: "C3L-00583-N",
  },
  {
    caseId: "C3N-03473",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03473-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02651",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-02651-T",
    normal_sample_id_protein: "C3L-02651-N",
  },
  {
    caseId: "C3N-00175",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00175-T",
    normal_sample_id_protein: "C3N-00175-N",
  },
  {
    caseId: "C3N-02087",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02087-T",
    normal_sample_id_protein: "C3N-02087-N",
  },
  {
    caseId: "C3N-02255",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-02255-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00307",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00307-T",
    normal_sample_id_protein: "C3N-00307-N",
  },
  {
    caseId: "C3L-04791",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-04791-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01520",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-01520-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04084",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-04084-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01971",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01971-T",
    normal_sample_id_protein: "C3L-01971-N",
  },
  {
    caseId: "C3L-02109",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02109-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00079",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00079-T",
    normal_sample_id_protein: "C3L-00079-N",
  },
  {
    caseId: "C3L-00767",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00767-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01818",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01818-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02648",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02648-T",
    normal_sample_id_protein: "C3L-02648-N",
  },
  {
    caseId: "C3N-02716",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02716-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00766",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00766-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00519",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00519-T",
    normal_sample_id_protein: "C3N-00519-N",
  },
  {
    caseId: "C3L-02897",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02897-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04027",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04027-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00299",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00299-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01560",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01560-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01165",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01165-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00011",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00011-T",
    normal_sample_id_protein: "C3L-00011-N",
  },
  {
    caseId: "C3L-01352",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01352-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01339",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-01339-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04072",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04072-T",
    normal_sample_id_protein: "C3L-04072-N",
  },
  {
    caseId: "C3L-00183",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00183-T",
    normal_sample_id_protein: "C3L-00183-N",
  },
  {
    caseId: "C3L-02701",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02701-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01074",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01074-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03027",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03027-T",
    normal_sample_id_protein: "C3N-03027-N",
  },
  {
    caseId: "C3L-01307",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-01307-T",
    normal_sample_id_protein: "C3L-01307-N",
  },
  {
    caseId: "C3L-01049",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01049-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-04848",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04848-T",
    normal_sample_id_protein: "C3L-04848-N",
  },
  {
    caseId: "C3N-01998",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01998-T",
    normal_sample_id_protein: "C3N-01998-N",
  },
  {
    caseId: "C3L-02704",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02704-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02542",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02542-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02339",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02339-T",
    normal_sample_id_protein: "C3N-02339-N",
  },
  {
    caseId: "C3N-00204",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-00204-T",
    normal_sample_id_protein: "C3N-00204-N",
  },
  {
    caseId: "C3L-01053",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01053-T",
    normal_sample_id_protein: "C3L-01053-N",
  },
  {
    caseId: "C3N-03008",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03008-T",
    normal_sample_id_protein: "C3N-03008-N",
  },
  {
    caseId: "C3L-00358",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00358-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02283",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02283-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03392",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03392-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00677",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-00677-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03272",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03272-T",
    normal_sample_id_protein: "C3L-03272-N",
  },
  {
    caseId: "C3N-03088",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-03088-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01375",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01375-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00368",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00368-T",
    normal_sample_id_protein: "C3L-00368-N",
  },
  {
    caseId: "C3N-03426",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03426-T",
    normal_sample_id_protein: "C3N-03426-N",
  },
  {
    caseId: "C3L-03728",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03728-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00662",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-00662-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01815",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01815-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02604",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02604-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00491",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00491-T",
    normal_sample_id_protein: "C3N-00491-N",
  },
  {
    caseId: "C3L-02705",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02705-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01715",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-01715-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03619",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03619-T",
    normal_sample_id_protein: "C3N-03619-N",
  },
  {
    caseId: "C3L-00568",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00568-T",
    normal_sample_id_protein: "C3L-00568-N",
  },
  {
    caseId: "C3N-00211",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-00211-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03395",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03395-T",
    normal_sample_id_protein: "C3L-03395-N",
  },
  {
    caseId: "C3L-00369",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00369-T",
    normal_sample_id_protein: "C3L-00369-N",
  },
  {
    caseId: "C3L-02707",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-02707-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02899",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02899-T",
    normal_sample_id_protein: "C3L-02899-N",
  },
  {
    caseId: "C3L-03405",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03405-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03630",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03630-T",
    normal_sample_id_protein: "C3L-03630-N",
  },
  {
    caseId: "C3L-01043",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01043-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00913",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00913-T",
    normal_sample_id_protein: "C3L-00913-N",
  },
  {
    caseId: "C3L-00096",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00096-T",
    normal_sample_id_protein: "C3L-00096-N",
  },
  {
    caseId: "C3L-02809",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-02809-T",
    normal_sample_id_protein: "C3L-02809-N",
  },
  {
    caseId: "C3L-01045",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01045-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02971",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-02971-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01200",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-01200-T",
    normal_sample_id_protein: "C3N-01200-N",
  },
  {
    caseId: "C3L-01302",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-01302-T",
    normal_sample_id_protein: "C3L-01302-N",
  },
  {
    caseId: "C3N-00223",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00223-T",
    normal_sample_id_protein: "C3N-00223-N",
  },
  {
    caseId: "C3N-03430",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03430-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03260",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03260-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00321",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00321-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02586",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-02586-T",
    normal_sample_id_protein: "C3N-02586-N",
  },
  {
    caseId: "C3L-02629",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02629-T",
    normal_sample_id_protein: "C3L-02629-N",
  },
  {
    caseId: "C3L-00510",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00510-T",
    normal_sample_id_protein: "C3L-00510-N",
  },
  {
    caseId: "C3L-01455",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-01455-T",
    normal_sample_id_protein: "C3L-01455-N",
  },
  {
    caseId: "C3N-03007",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03007-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03173",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03173-T",
    normal_sample_id_protein: "C3N-03173-N",
  },
  {
    caseId: "C3N-03780",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03780-T",
    normal_sample_id_protein: "C3N-03780-N",
  },
  {
    caseId: "C3L-04080",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-04080-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00578",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00578-T",
    normal_sample_id_protein: "C3N-00578-N",
  },
  {
    caseId: "C3N-03069",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03069-T",
    normal_sample_id_protein: "C3N-03069-N",
  },
  {
    caseId: "C3L-00893",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3L-00893-T",
    normal_sample_id_protein: "C3L-00893-N",
  },
  {
    caseId: "C3N-01515",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01515-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-03388",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-03388-T",
    normal_sample_id_protein: "C3L-03388-N",
  },
  {
    caseId: "C3L-00814",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00814-T",
    normal_sample_id_protein: "C3L-00814-N",
  },
  {
    caseId: "C3N-01334",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01334-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00435",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00435-T",
    normal_sample_id_protein: "C3N-00435-N",
  },
  {
    caseId: "C3L-03961",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03961-T",
    normal_sample_id_protein: "C3L-03961-N",
  },
  {
    caseId: "C3L-04013",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-04013-T",
    normal_sample_id_protein: "C3L-04013-N",
  },
  {
    caseId: "C3L-03962",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-03962-T",
    normal_sample_id_protein: "C3L-03962-N",
  },
  {
    caseId: "C3L-00771",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3L-00771-T",
    normal_sample_id_protein: "C3L-00771-N",
  },
  {
    caseId: "C3L-03681",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-03681-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03662",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03662-T",
    normal_sample_id_protein: "C3N-03662-N",
  },
  {
    caseId: "C3N-00663",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-00663-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04155",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-04155-T",
    normal_sample_id_protein: "C3N-04155-N",
  },
  {
    caseId: "C3L-00881",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-00881-T",
    normal_sample_id_protein: "C3L-00881-N",
  },
  {
    caseId: "C3N-04284",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-04284-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00433",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00433-T",
    normal_sample_id_protein: "C3N-00433-N",
  },
  {
    caseId: "C3N-01071",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-01071-T",
    normal_sample_id_protein: "C3N-01071-N",
  },
  {
    caseId: "C3L-01051",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01051-T",
    normal_sample_id_protein: "C3L-01051-N",
  },
  {
    caseId: "C3L-00812",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00812-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01142",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3L-01142-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01852",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01852-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-02700",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-02700-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-04283",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-04283-T",
    normal_sample_id_protein: "C3N-04283-N",
  },
  {
    caseId: "C3N-00573",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00573-T",
    normal_sample_id_protein: "C3N-00573-N",
  },
  {
    caseId: "C3N-01366",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01366-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-02170",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02170-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-00813",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3L-00813-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01453",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3L-01453-T",
    normal_sample_id_protein: "C3L-01453-N",
  },
  {
    caseId: "C3L-02665",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-02665-T",
    normal_sample_id_protein: "C3L-02665-N",
  },
  {
    caseId: "C3L-00445",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00445-T",
    normal_sample_id_protein: "C3L-00445-N",
  },
  {
    caseId: "C3L-00993",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3L-00993-T",
    normal_sample_id_protein: "C3L-00993-N",
  },
  {
    caseId: "C3L-00995",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-00995-T",
    normal_sample_id_protein: "C3L-00995-N",
  },
  {
    caseId: "C3N-00150",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00150-T",
    normal_sample_id_protein: "C3N-00150-N",
  },
  {
    caseId: "C3N-00148",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00148-T",
    normal_sample_id_protein: "C3N-00148-N",
  },
  {
    caseId: "C3N-00646",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00646-T",
    normal_sample_id_protein: "C3N-00646-N",
  },
  {
    caseId: "C3N-00149",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00149-T",
    normal_sample_id_protein: "C3N-00149-N",
  },
  {
    caseId: "C3N-00154",
    tumor_code: "CCRCC",
    tumor_sample_id_protein: "C3N-00154-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3L-01237",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3L-01237-T",
    normal_sample_id_protein: "C3L-01237-N",
  },
  {
    caseId: "C3N-01025",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-01025-T",
    normal_sample_id_protein: "C3N-01025-N",
  },
  {
    caseId: "11LU022",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "11LU022-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11LU035",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "11LU035-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-NPJ7",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-P44H",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-Q2AG",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-QVJO",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-R55F",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-RN5K",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-RU72",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-UTHO",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-WVLH",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "PT-Y8DK",
    tumor_code: "GBM",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "CPT001846",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "CPT000814",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR047",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR047-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR043",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR043-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR049",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR049-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR023",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR023-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR010",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "06BR003",
    tumor_code: "BR",
    tumor_sample_id_protein: "06BR003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR074",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR074-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR017",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR017",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "06BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "06BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "09BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "09BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR017",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR015",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR015-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR025",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR025-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR001",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR031",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR031-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR032",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR032-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR045",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR045-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR053",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR053-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR027",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR027-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14BR008",
    tumor_code: "BR",
    tumor_sample_id_protein: "14BR008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR002",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR002-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR013",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR032",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR032-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "21BR010",
    tumor_code: "BR",
    tumor_sample_id_protein: "21BR010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR004",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR003",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR028",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR028-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR004",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR073",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR073-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR009",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR019",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR019-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR001",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "09BR007",
    tumor_code: "BR",
    tumor_sample_id_protein: "09BR007-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR020",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR020-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR002",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR002-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR019",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR019-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR008",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR033",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR033-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR022",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR022-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR013",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR010",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR007",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR007-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR030",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR030-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR007",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR007-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR050",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR050-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR001",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR014",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR014-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "22BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "22BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR010",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR031",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR031-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR011",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR011-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR003",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR024",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR024-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR016",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR016-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR030",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR030-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR018",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR018-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR042",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR042-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR054",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR054-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR044",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR044-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "14BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR044",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR044-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR016",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR016-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR055",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR055-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "06BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "06BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR042",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR042-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR018",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR018-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR009",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR025",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR025-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR015",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR015-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR040",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR040-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR004",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR057",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR057-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR043",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR043-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR003",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR029",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR029-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR043",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR043-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR026",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR026-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR056",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR056-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR038",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR038-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR036",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR036-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR009",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "22BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "22BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR072",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR072-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR027",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR027-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR040",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR040-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR059",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR059-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR051",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR051-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR038",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR038-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR060",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR060-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "09BR004",
    tumor_code: "BR",
    tumor_sample_id_protein: "09BR004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR075",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR075-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR002",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR002-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR078",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR078-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "16BR012",
    tumor_code: "BR",
    tumor_sample_id_protein: "16BR012-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "06BR014",
    tumor_code: "BR",
    tumor_sample_id_protein: "06BR014-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR076",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR076-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR016",
    tumor_code: "BR",
    tumor_sample_id_protein: "18BR016-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR005",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "13BR009",
    tumor_code: "BR",
    tumor_sample_id_protein: "13BR009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR080",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR080-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR042",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR042-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "15BR003",
    tumor_code: "BR",
    tumor_sample_id_protein: "15BR003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14BR014",
    tumor_code: "BR",
    tumor_sample_id_protein: "14BR014-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR058",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR058-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR012",
    tumor_code: "BR",
    tumor_sample_id_protein: "11BR012-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR009",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR008",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "21BR001",
    tumor_code: "BR",
    tumor_sample_id_protein: "21BR001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "21BR002",
    tumor_code: "BR",
    tumor_sample_id_protein: "21BR002-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR026",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR026-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR004",
    tumor_code: "BR",
    tumor_sample_id_protein: "05BR004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR023",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR023-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR020",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR020-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20BR006",
    tumor_code: "BR",
    tumor_sample_id_protein: "20BR006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "09BR001",
    tumor_code: "BR",
    tumor_sample_id_protein: "09BR001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR011",
    tumor_code: "BR",
    tumor_sample_id_protein: "03BR011-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR010",
    tumor_code: "BR",
    tumor_sample_id_protein: "01BR010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11CO037",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO037-T",
    normal_sample_id_protein: "11CO037-N",
  },
  {
    caseId: "11CO051",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO051-T",
    normal_sample_id_protein: "11CO051-N",
  },
  {
    caseId: "01CO008",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO008-T",
    normal_sample_id_protein: "01CO008-N",
  },
  {
    caseId: "22CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "22CO006-T",
    normal_sample_id_protein: "22CO006-N",
  },
  {
    caseId: "05CO002",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO002-T",
    normal_sample_id_protein: "05CO002-N",
  },
  {
    caseId: "11CO036",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO036-T",
    normal_sample_id_protein: "11CO036-N",
  },
  {
    caseId: "20CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "20CO006-T",
    normal_sample_id_protein: "20CO006-N",
  },
  {
    caseId: "01CO019",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO019-T",
    normal_sample_id_protein: "01CO019-N",
  },
  {
    caseId: "09CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO005-T",
    normal_sample_id_protein: "09CO005-N",
  },
  {
    caseId: "05CO035",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO035-T",
    normal_sample_id_protein: "05CO035-N",
  },
  {
    caseId: "05CO053",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO053-T",
    normal_sample_id_protein: "05CO053-N",
  },
  {
    caseId: "20CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: "20CO001-T",
    normal_sample_id_protein: "20CO001-N",
  },
  {
    caseId: "05CO045",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO045-T",
    normal_sample_id_protein: "05CO045-N",
  },
  {
    caseId: "15CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: "15CO001-T",
    normal_sample_id_protein: "15CO001-N",
  },
  {
    caseId: "05CO044",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO044-T",
    normal_sample_id_protein: "05CO044-N",
  },
  {
    caseId: "11CO053",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO053-T",
    normal_sample_id_protein: "11CO053-N",
  },
  {
    caseId: "11CO019",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO019-T",
    normal_sample_id_protein: "11CO019-N",
  },
  {
    caseId: "20CO007",
    tumor_code: "CO",
    tumor_sample_id_protein: "20CO007-T",
    normal_sample_id_protein: "20CO007-N",
  },
  {
    caseId: "05CO041",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO041-T",
    normal_sample_id_protein: "05CO041-N",
  },
  {
    caseId: "11CO042",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO042-T",
    normal_sample_id_protein: "11CO042-N",
  },
  {
    caseId: "05CO033",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO033-T",
    normal_sample_id_protein: "05CO033-N",
  },
  {
    caseId: "11CO008",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO008-T",
    normal_sample_id_protein: "11CO008-N",
  },
  {
    caseId: "09CO008",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO008-T",
    normal_sample_id_protein: "09CO008-N",
  },
  {
    caseId: "11CO033",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO033-T",
    normal_sample_id_protein: "11CO033-N",
  },
  {
    caseId: "05CO039",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO039-T",
    normal_sample_id_protein: "05CO039-N",
  },
  {
    caseId: "11CO020",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO020-T",
    normal_sample_id_protein: "11CO020-N",
  },
  {
    caseId: "21CO007",
    tumor_code: "CO",
    tumor_sample_id_protein: "21CO007-T",
    normal_sample_id_protein: "21CO007-N",
  },
  {
    caseId: "05CO034",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO034-T",
    normal_sample_id_protein: "05CO034-N",
  },
  {
    caseId: "05CO007",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "05CO007-N",
  },
  {
    caseId: "05CO054",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO054-T",
    normal_sample_id_protein: "05CO054-N",
  },
  {
    caseId: "05CO029",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO029-T",
    normal_sample_id_protein: "05CO029-N",
  },
  {
    caseId: "20CO003",
    tumor_code: "CO",
    tumor_sample_id_protein: "20CO003-T",
    normal_sample_id_protein: "20CO003-N",
  },
  {
    caseId: "01CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO006-T",
    normal_sample_id_protein: "01CO006-N",
  },
  {
    caseId: "15CO002",
    tumor_code: "CO",
    tumor_sample_id_protein: "15CO002-T",
    normal_sample_id_protein: "15CO002-N",
  },
  {
    caseId: "11CO022",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO022-T",
    normal_sample_id_protein: "11CO022-N",
  },
  {
    caseId: "05CO050",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO050-T",
    normal_sample_id_protein: "05CO050-N",
  },
  {
    caseId: "09CO019",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO019-T",
    normal_sample_id_protein: "09CO019-N",
  },
  {
    caseId: "11CO032",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO032-T",
    normal_sample_id_protein: "11CO032-N",
  },
  {
    caseId: "11CO043",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO043-T",
    normal_sample_id_protein: "11CO043-N",
  },
  {
    caseId: "05CO011",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO011-T",
    normal_sample_id_protein: "05CO011-N",
  },
  {
    caseId: "11CO021",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO021-T",
    normal_sample_id_protein: "11CO021-N",
  },
  {
    caseId: "11CO018",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO018-T",
    normal_sample_id_protein: "11CO018-N",
  },
  {
    caseId: "01CO014",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO014-T",
    normal_sample_id_protein: "01CO014-N",
  },
  {
    caseId: "11CO077",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO077-T",
    normal_sample_id_protein: "11CO077-N",
  },
  {
    caseId: "22CO004",
    tumor_code: "CO",
    tumor_sample_id_protein: "22CO004-T",
    normal_sample_id_protein: "22CO004-N",
  },
  {
    caseId: "09CO014",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO014-T",
    normal_sample_id_protein: "09CO014-N",
  },
  {
    caseId: "09CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO006-T",
    normal_sample_id_protein: "09CO006-N",
  },
  {
    caseId: "16CO011",
    tumor_code: "CO",
    tumor_sample_id_protein: "16CO011-T",
    normal_sample_id_protein: "16CO011-N",
  },
  {
    caseId: "27CO004",
    tumor_code: "CO",
    tumor_sample_id_protein: "27CO004-T",
    normal_sample_id_protein: "27CO004-N",
  },
  {
    caseId: "09CO015",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO015-T",
    normal_sample_id_protein: "09CO015-N",
  },
  {
    caseId: "11CO070",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO070-T",
    normal_sample_id_protein: "11CO070-N",
  },
  {
    caseId: "09CO018",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO018-T",
    normal_sample_id_protein: "09CO018-N",
  },
  {
    caseId: "01CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "01CO001-N",
  },
  {
    caseId: "14CO002",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "14CO002-N",
  },
  {
    caseId: "11CO054",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO054-T",
    normal_sample_id_protein: "11CO054-N",
  },
  {
    caseId: "09CO011",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO011-T",
    normal_sample_id_protein: "09CO011-N",
  },
  {
    caseId: "16CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "16CO006-T",
    normal_sample_id_protein: "16CO006-N",
  },
  {
    caseId: "20CO004",
    tumor_code: "CO",
    tumor_sample_id_protein: "20CO004-T",
    normal_sample_id_protein: "20CO004-N",
  },
  {
    caseId: "05CO003",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO003-T",
    normal_sample_id_protein: "05CO003-N",
  },
  {
    caseId: "05CO032",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO032-T",
    normal_sample_id_protein: "05CO032-N",
  },
  {
    caseId: "11CO052",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO052-T",
    normal_sample_id_protein: "11CO052-N",
  },
  {
    caseId: "05CO020",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO020-T",
    normal_sample_id_protein: "05CO020-N",
  },
  {
    caseId: "05CO049",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO049-T",
    normal_sample_id_protein: "05CO049-N",
  },
  {
    caseId: "05CO037",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO037-T",
    normal_sample_id_protein: "05CO037-N",
  },
  {
    caseId: "11CO031",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO031-T",
    normal_sample_id_protein: "11CO031-N",
  },
  {
    caseId: "05CO015",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO015-T",
    normal_sample_id_protein: "05CO015-N",
  },
  {
    caseId: "05CO048",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO048-T",
    normal_sample_id_protein: "05CO048-N",
  },
  {
    caseId: "05CO026",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO026-T",
    normal_sample_id_protein: "05CO026-N",
  },
  {
    caseId: "11CO007",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO007-T",
    normal_sample_id_protein: "11CO007-N",
  },
  {
    caseId: "11CO048",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO048-T",
    normal_sample_id_protein: "11CO048-N",
  },
  {
    caseId: "11CO045",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO045-T",
    normal_sample_id_protein: "11CO045-N",
  },
  {
    caseId: "14CO003",
    tumor_code: "CO",
    tumor_sample_id_protein: "14CO003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "21CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "21CO006-T",
    normal_sample_id_protein: "21CO006-N",
  },
  {
    caseId: "11CO030",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO030-T",
    normal_sample_id_protein: "11CO030-N",
  },
  {
    caseId: "11CO027",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO027-T",
    normal_sample_id_protein: "11CO027-N",
  },
  {
    caseId: "11CO061",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO061-T",
    normal_sample_id_protein: "11CO061-N",
  },
  {
    caseId: "11CO062",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO062-T",
    normal_sample_id_protein: "11CO062-N",
  },
  {
    caseId: "01CO013",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO013-T",
    normal_sample_id_protein: "01CO013-N",
  },
  {
    caseId: "11CO047",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO047-T",
    normal_sample_id_protein: "11CO047-N",
  },
  {
    caseId: "11CO072",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO072-T",
    normal_sample_id_protein: "11CO072-N",
  },
  {
    caseId: "14CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: "14CO005-T",
    normal_sample_id_protein: "14CO005-N",
  },
  {
    caseId: "01CO015",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO015-T",
    normal_sample_id_protein: "01CO015-N",
  },
  {
    caseId: "11CO044",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO044-T",
    normal_sample_id_protein: "11CO044-N",
  },
  {
    caseId: "09CO022",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO022-T",
    normal_sample_id_protein: "09CO022-N",
  },
  {
    caseId: "01CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO005-T",
    normal_sample_id_protein: "01CO005-N",
  },
  {
    caseId: "05CO028",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO028-T",
    normal_sample_id_protein: "05CO028-N",
  },
  {
    caseId: "11CO079",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO079-T",
    normal_sample_id_protein: "11CO079-N",
  },
  {
    caseId: "11CO057",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO057-T",
    normal_sample_id_protein: "11CO057-N",
  },
  {
    caseId: "05CO047",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO047-T",
    normal_sample_id_protein: "05CO047-N",
  },
  {
    caseId: "11CO010",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO010-T",
    normal_sample_id_protein: "11CO010-N",
  },
  {
    caseId: "01CO022",
    tumor_code: "CO",
    tumor_sample_id_protein: "01CO022-T",
    normal_sample_id_protein: "01CO022-N",
  },
  {
    caseId: "11CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO005-T",
    normal_sample_id_protein: "11CO005-N",
  },
  {
    caseId: "11CO039",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO039-T",
    normal_sample_id_protein: "11CO039-N",
  },
  {
    caseId: "06CO002",
    tumor_code: "CO",
    tumor_sample_id_protein: "06CO002-T",
    normal_sample_id_protein: "06CO002-N",
  },
  {
    caseId: "11CO060",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "11CO060-N",
  },
  {
    caseId: "09CO013",
    tumor_code: "CO",
    tumor_sample_id_protein: "09CO013-T",
    normal_sample_id_protein: "09CO013-N",
  },
  {
    caseId: "16CO002",
    tumor_code: "CO",
    tumor_sample_id_protein: "16CO002-T",
    normal_sample_id_protein: "16CO002-N",
  },
  {
    caseId: "11CO058",
    tumor_code: "CO",
    tumor_sample_id_protein: "11CO058-T",
    normal_sample_id_protein: "11CO058-N",
  },
  {
    caseId: "05CO006",
    tumor_code: "CO",
    tumor_sample_id_protein: "05CO006-T",
    normal_sample_id_protein: "05CO006-N",
  },
  {
    caseId: "06CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: "06CO001-T",
    normal_sample_id_protein: "06CO001-N",
  },
  {
    caseId: "16CO003",
    tumor_code: "CO",
    tumor_sample_id_protein: "16CO003-T",
    normal_sample_id_protein: "16CO003-N",
  },
  {
    caseId: "C3N-01367",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01367-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-01369",
    tumor_code: "GBM",
    tumor_sample_id_protein: "C3N-01369-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03045",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03045-T",
    normal_sample_id_protein: "C3N-03045-N",
  },
  {
    caseId: "C3N-04611",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-04611-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03042",
    tumor_code: "HNSCC",
    tumor_sample_id_protein: "C3N-03042-T",
    normal_sample_id_protein: "C3N-03042-N",
  },
  {
    caseId: "C3N-02252",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02252-T",
    normal_sample_id_protein: "C3N-02252-N",
  },
  {
    caseId: "C3N-02494",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02494-T",
    normal_sample_id_protein: "C3N-02494-N",
  },
  {
    caseId: "C3N-03092",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03092-T",
    normal_sample_id_protein: "C3N-03092-N",
  },
  {
    caseId: "C3N-02523",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-02523-T",
    normal_sample_id_protein: "C3N-02523-N",
  },
  {
    caseId: "C3N-03093",
    tumor_code: "LSCC",
    tumor_sample_id_protein: "C3N-03093-T",
    normal_sample_id_protein: "C3N-03093-N",
  },
  {
    caseId: "11LU013",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "11LU013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00203",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00203-T",
    normal_sample_id_protein: "C3N-00203-N",
  },
  {
    caseId: "C3N-00704",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00704-T",
    normal_sample_id_protein: "C3N-00704-N",
  },
  {
    caseId: "C3N-00738",
    tumor_code: "LUAD",
    tumor_sample_id_protein: "C3N-00738-T",
    normal_sample_id_protein: "C3N-00738-N",
  },
  {
    caseId: "02OV032",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV032-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV057",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV057-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV028",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV028-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "15OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: "15OV001-T",
    normal_sample_id_protein: "15OV001-N",
  },
  {
    caseId: "17OV011",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV011-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV007",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV007-T",
    normal_sample_id_protein: "01OV007-N",
  },
  {
    caseId: "04OV018",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV018-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV063",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV063-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV003",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "17OV003-N",
  },
  {
    caseId: "01OV023",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV023-T",
    normal_sample_id_protein: "01OV023-N",
  },
  {
    caseId: "17OV039",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV039-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV015",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV015-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV017",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV005",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "17OV005-N",
  },
  {
    caseId: "17OV028",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV028-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV008",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV005",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV017",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV017-T",
    normal_sample_id_protein: "01OV017-N",
  },
  {
    caseId: "02OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11OV002",
    tumor_code: "OV",
    tumor_sample_id_protein: "11OV002-T",
    normal_sample_id_protein: "11OV002-N",
  },
  {
    caseId: "02OV041",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV041-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV025",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV025-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV018",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV018-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV029",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV029-T",
    normal_sample_id_protein: "01OV029-N",
  },
  {
    caseId: "17OV010",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV010-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV027",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV027-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "20OV005",
    tumor_code: "OV",
    tumor_sample_id_protein: "20OV005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14OV011",
    tumor_code: "OV",
    tumor_sample_id_protein: "14OV011-T",
    normal_sample_id_protein: "14OV011-N",
  },
  {
    caseId: "02OV006",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV006-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV009",
    tumor_code: "OV",
    tumor_sample_id_protein: "26OV009-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV036",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV036-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV008",
    tumor_code: "OV",
    tumor_sample_id_protein: "26OV008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV004",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "17OV004-N",
  },
  {
    caseId: "02OV023",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV023-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV039",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV039-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV037",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV037-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV051",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV051-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV040",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV040-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV030",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV030-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV002",
    tumor_code: "OV",
    tumor_sample_id_protein: "26OV002-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV046",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV046-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV039",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV039-T",
    normal_sample_id_protein: "01OV039-N",
  },
  {
    caseId: "17OV014",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV014-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV040",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV040-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV033",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV033-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV033",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV033-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV053",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV053-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV018",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV018-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV050",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV050-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV021",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV021-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV054",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV054-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV048",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV048-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV017",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV017-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV023",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV023-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV031",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV031-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV013",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "01OV013-N",
  },
  {
    caseId: "18OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: "18OV001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV013",
    tumor_code: "OV",
    tumor_sample_id_protein: "26OV013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV015",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV015-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV055",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV055-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV005",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV005-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV036",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV036-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11OV010",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "11OV010-N",
  },
  {
    caseId: "04OV011",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV011-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV024",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV024-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV019",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "01OV019-N",
  },
  {
    caseId: "01OV041",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV041-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV045",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV045-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV008",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "01OV008-N",
  },
  {
    caseId: "01OV026",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV026-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV011",
    tumor_code: "OV",
    tumor_sample_id_protein: "26OV011-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV029",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV029-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV013",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV030",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV030-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV012",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV012-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14OV029",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "14OV029-N",
  },
  {
    caseId: "04OV036",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV036-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "13OV003",
    tumor_code: "OV",
    tumor_sample_id_protein: "13OV003-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV010",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "01OV010-N",
  },
  {
    caseId: "04OV044",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV044-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV013",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV013-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV022",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV022-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV002",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV002-T",
    normal_sample_id_protein: "17OV002-N",
  },
  {
    caseId: "04OV058",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV058-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV027",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV027-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV047",
    tumor_code: "OV",
    tumor_sample_id_protein: "01OV047-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV001-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV044",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV044-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: "17OV001-T",
    normal_sample_id_protein: "17OV001-N",
  },
  {
    caseId: "04OV049",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV049-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV008",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV008-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV004",
    tumor_code: "OV",
    tumor_sample_id_protein: "04OV004-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV029",
    tumor_code: "OV",
    tumor_sample_id_protein: "02OV029-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-03039",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-03039-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "C3N-00709",
    tumor_code: "PDA",
    tumor_sample_id_protein: "C3N-00709-T",
    normal_sample_id_protein: "C3N-00709-N",
  },
  {
    caseId: "C3L-03513",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-03513-N",
  },
  {
    caseId: "C3L-03514",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-03514-N",
  },
  {
    caseId: "C3L-03515",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-03515-N",
  },
  {
    caseId: "C3L-07032",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-07032-N",
  },
  {
    caseId: "C3L-07033",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-07033-N",
  },
  {
    caseId: "C3L-07034",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-07034-N",
  },
  {
    caseId: "C3L-07035",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-07035-N",
  },
  {
    caseId: "C3L-07036",
    tumor_code: "PDA",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-07036-N",
  },
  {
    caseId: "C3L-00930",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "C3L-00930-N",
  },
  {
    caseId: "NX1",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX1-N",
  },
  {
    caseId: "NX2",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX2-N",
  },
  {
    caseId: "NX3",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX3-N",
  },
  {
    caseId: "NX4",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX4-N",
  },
  {
    caseId: "NX5",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX5-N",
  },
  {
    caseId: "NX6",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX6-N",
  },
  {
    caseId: "NX7",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX7-N",
  },
  {
    caseId: "NX8",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX8-N",
  },
  {
    caseId: "NX9",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX9-N",
  },
  {
    caseId: "C3N-00151",
    tumor_code: "UCEC",
    tumor_sample_id_protein: "C3N-00151-T",
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "NX12",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX12-N",
  },
  {
    caseId: "NX17",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX17-N",
  },
  {
    caseId: "NX13",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX13-N",
  },
  {
    caseId: "NX14",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX14-N",
  },
  {
    caseId: "NX10",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX10-N",
  },
  {
    caseId: "NX16",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX16-N",
  },
  {
    caseId: "NX18",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX18-N",
  },
  {
    caseId: "NX11",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX11-N",
  },
  {
    caseId: "NX15",
    tumor_code: "UCEC",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: "NX15-N",
  },
  {
    caseId: "604",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "1488",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "100002921",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "100003304",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "100004012",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "100004028",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01BR044",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "03BR012",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR031",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR051",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR052",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR055",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05BR058",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11BR069",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "18BR008",
    tumor_code: "BR",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05CO004",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05CO014",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "05CO055",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11CO059",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "16CO012",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "24CO005",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV002",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV045",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV046",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "01OV049",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV035",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV040",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV042",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "02OV045",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "04OV041",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "11OV009",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "13OV004",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV019",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "17OV034",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "22OV001",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "26OV010",
    tumor_code: "OV",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "13CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
  {
    caseId: "14CO001",
    tumor_code: "CO",
    tumor_sample_id_protein: NaN,
    normal_sample_id_protein: NaN,
  },
];
