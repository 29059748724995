<template>
  <div class="heatmap-normalization-selector">
        <v-radio-group 
            v-model="option1Selection"
            label="Normalize by:"
        >
            <v-radio
                v-for="n in option1"
                :key="n.label"
                :label="`${n.label}`"
                :value="n"
            ></v-radio>
        </v-radio-group>
  </div>
</template>

<script>
export default {
    name: 'heatmap-normalization-selector',

    data() {
        return {
            option1: [
                {
                    label: 'tumor and normal together',
                    normalizeCombined: true,
                },
                {
                    label: 'tumor and normal separately',
                    normalizeCombined: false,
                },
            ],
            option1Selection: { "label": "tumor and normal combined", "normalizeCombined": true },
        }
    },

    watch: {
        option1Selection() {
            this.$store.dispatch(
                'setNormalizeCombined', 
                {
                     normalizeCombined: this.option1Selection.normalizeCombined 
                }
            )
        },
    }
}
</script>

<style>
.heatmap-normalization-selector {
    width: 100%;
}
</style>