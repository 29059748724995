<template>
  <div class="pathway-selector">
    <v-card flat style="width: 390px;">
        <v-card-title>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        </v-card-title>
        <v-data-table
            v-model="selected"
            show-select
            :headers="headers"
            :items="pathwayIdsFiltered"
            :search="search"
            dense
            :footer-props="{
              'disable-items-per-page': true,
              showFirstLastPage: false,
              'items-per-page-text': ''
            }"
        >
        </v-data-table>
    </v-card>
      <div class="pathways-selected" v-if="selected.length > 0">
        <v-btn color="indigo white--text my-2" @click="setPathwaysHeatmap">
          Render heatmap of {{ selected.length }} ssGSEA scores
        </v-btn>
        <div 
          class="selected-pathway" 
          v-for="pathway in selected" 
          :key="pathway.id"
          @click="removePathway({id: pathway.id})"
        >
        <v-btn
          icon
          x-small
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
          {{ pathway.name }}
        </div>
        <div style="width: 50%;">
          <v-btn color="grey white--text my-2" @click="clearSelection">
              Clear all
            </v-btn>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'pathway-selector',

    computed: {
      pathwayIds() { return this.$store.state.pathwayIds },
      pathwayIdsFiltered() {
        return this.pathwayIds.filter(el => el.db === this.pathwayDatabase)
      },
      pathwayDatabase() { return this.$store.state.pathwayDatabase },
    },

    data () {
      return {
        search: '',
        selected: [
        ],
        headers: [
          {
            text: 'Pathway name',
            align: 'start',
            filterable: true,
            value: 'name',
            width: '50%',
          },
          { 
            text: 'Members', 
            value: 'members',
            width: '50%',
          },
        ],
      }
    },
    methods: {
      setPathwaysHeatmap() {
        this.$store.dispatch('setPathwaysHeatmap', { pathways: this.selected })
        this.$store.dispatch('updateActiveTabHeatmap', { activeTabHeatmap: 1 })
      },
      removePathway({ id }) {
        this.selected = this.selected.filter(pathway => pathway.id !== id)
      },
      clearSelection() { this.selected = [] },
    },

    mounted() {
    },
}
</script>

<style>
.pathway-selector { 
    max-width: 390px;
    min-width: 390px;
}

.pathways-selected {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
}

.selected-pathway {
  border: solid 1px #ebebeb;
}
</style>