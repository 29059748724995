<template>
    <div class="tab2-customize-and-download">
        <v-expansion-panels 
          v-model="customizePanels"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-2 ">
              Normalization options
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <heatmap-normalization-selector />
            </v-expansion-panel-content>
          </v-expansion-panel>
        
          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-2 ">
              Sort tracks
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <heatmap-sorter />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-2 ">
             Custom cohort
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <heatmap-custom-cohort />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-2 ">
             Download
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <download-table />
              <!-- <download-table/> -->
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>


      <!-- <select-and-sort-box />
      <toggle-categorical-container />
      <custom-cohort /> -->
    </div>
</template>

<script>
import DownloadTable from './DownloadTable.vue'
import HeatmapCustomCohort from './HeatmapCustomCohort.vue'
import HeatmapNormalizationSelector from './HeatmapNormalizationSelector.vue'
import HeatmapSorter from './HeatmapSorter.vue'

export default {
  name: "tab2-customize-and-download",
  
  components: {
    HeatmapSorter,
    HeatmapCustomCohort,
    HeatmapNormalizationSelector,
    DownloadTable,
  },

  data() { 
    return {
      customizePanels: [ 1 ]
    }
  },
  
  mounted() {
    const useCustomCohort = this.$store.state.useCustomCohort

    if (useCustomCohort) {
      this.customizePanels.push(2)
    }
  },
}
</script>

<style scoped>
    .tab2-customize-and-download {
      width: 85%;
      margin: 20px auto;
    }
    
    .tumor-lock-toggle {
        margin: 0px;
    }

    .heatmap-options {
        border: solid 1px black;
        padding: 10px;
    }

    .score-selector {
        display: flex;
        flex-direction: row;
        justify-content: left;
    }
</style>