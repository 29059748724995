export default ({
    category,
    divId,
    percentageView,
    sampleMeta,
    tumors,
}) => {
    const Plotly = window.Plotly
    
    const tumorCounts = tumors.map(tumor => 
            sampleMeta
                .filter((meta) => {
                    return meta.Tumor === tumor
                })
                .map((meta) => meta.caseId)
                .length
    )

    let data = category.values.map(value => {
        return {
            x: tumors,
            y: tumors.map(tumor => 
                sampleMeta
                    .filter((meta) => {
                        return meta.Tumor === tumor && 
                            meta[category.title] === value.label
                    })
                    .map((meta) => meta.caseId)
                    .length
                ),
            customData: tumors.map(tumor => 
                sampleMeta
                    .filter((meta) => {
                        return meta.Tumor === tumor && 
                            meta[category.title] === value.label
                    })
                    .map((meta) => meta.caseId)
            ),
            name: value.label.toString(),
            type: 'bar',
            marker: {
                color: value.color,
            },
        }
    })

    if (percentageView) {
        data.forEach(el => {
            let y = el.y

            const percentY = el.y.map((valCount, i) => {
                return (valCount / tumorCounts[i]) * 100
            })
            el.y = percentY
        })
    }

    const layout = {
        barmode: 'stack',
        title: category.title,
        yaxis: {
            title: percentageView ? '% cases' : 'cases',
        },
        width: 400,
    }

    const config = {
        responsive: false,
    }
      
    Plotly.newPlot(divId, data, layout, config);

    return document.getElementById(divId)
}

function deepClone(o) {
    return JSON.parse(JSON.stringify(o))
  }
  