<template>
  <div class="heatmap-legend-category-element">
      <v-btn
        :color="element.color"
        x-small
        @click="toggleLegendElement"
        :text="!heatmapShow[category.title][element.label]"
        :class="element.light ? 'grey--text' : ''"
      >
        {{ element.label }}
      </v-btn>
  </div>
</template>

<script>

export default {
    components: {},

    props: ['category', 'element' ],

    name: 'heatmap-legend-category-element',

    computed: {
      heatmapShow() { return this.$store.state.heatmapShow },
    },

    methods: {
      toggleLegendElement() {
        this.$store.dispatch('toggleLegendElement', {
          category: this.category.title,
          label: this.element.label,
        })
      }
    },
}
</script>

<style>
.heatmap-legend-category-element {
    width: 100%;
}
</style>