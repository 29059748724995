<template>
  <div class="download-table">
      <v-btn
        @click="downloadExcel"
        color="teal lighten-3"
      >
        <v-icon light>mdi-microsoft-excel</v-icon>
        Pathway scores
      </v-btn>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'

export default {
    name: 'download-table',

    computed: {
        combinedHeatmapData() { return this.$store.state.combinedHeatmapData },
        normalizeCombined() { return this.$store.state.normalizeCombined },
    },

    methods: {
        downloadExcel() {
            console.log('combinedHeatmapData', this.combinedHeatmapData)
            const wb = XLSX.utils.book_new();
            const categories = ['name']
            const samples = ['okay']

            let suffix = this.normalizeCombined ? ' TNcombined' : ' TNindependent'

            const excelArrJson = this.combinedHeatmapData.map(el => {
                let res = {
                    'case': el.caseId,
                }
                const pathways = Object.keys(el).filter(p => p.includes(suffix))
                pathways.forEach(pathway => {
                    const pathwayKey = pathway.split(suffix)[0]
                    res[pathwayKey] = el[pathway]
                })
                return res
            })
            // const excelArrJson = [
            //     { name: 'Moran', role: 'back' },
            //     { name: 'Alain', role: 'front' },
            //     { name: 'Tony', role: 'back' },
            //     { name: 'Mike', role: 'back' },
            //     { name: 'Abo', role: 'back' },
            //     { name: 'Toni', role: 'back' },
            // ]
            // const excelArr = this.samples.map((sample) => {
            //     let res = { sample }
            //     this.pathwayScores.forEach((pathwayGroup) => {
            //         Object.entries(pathwayGroup).forEach(([pathway, scores]) => {
            //         res[pathway] = scores[sample] > -Infinity ? scores[sample] : ''
            //         })
            //     })
            //     categories.forEach((category) => {
            //         res[category] = this.sampleMeta[sample][category]
            //     })
            //     return res
            // })

            const worksheet = XLSX.utils.json_to_sheet(excelArrJson)
            const label = ''
            XLSX.utils.book_append_sheet(
                wb, 
                worksheet, 
                label.length > 21 ? label.slice(0,20) : label
            )
            XLSX.writeFile(wb, `protrack-pancan-pw.xls`);
        }
    }
}
</script>

<style>
.download-table {
    width: 100%;
    margin: 10px;
}
</style>