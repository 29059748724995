<template>
    <div class="sample-dashboard">
      <tumor-selector-container />
      <v-container>
        <v-row no-gutters 
          v-for="(categoryChunk, i) in categoryChunks" 
          :key="`category-${i}`"
        >
          <v-col
            v-for="category in categoryChunk"
            :key="category.title"
            cols="12"
            sm="4"
          >
            <barplotly-container 
              :key="category.title"
              :category="category"
            />
          </v-col>
        </v-row>
    
      </v-container>
    </div>
</template>

<script>
import BarplotlyContainer from './BarplotlyContainer.vue'
import TumorSelectorContainer from './TumorSelectorContainer.vue'

export default {
  components: { 
    BarplotlyContainer,
    TumorSelectorContainer,
  },
  name: "sample-dashboard",
  data() {
    return {
    }
  },
  computed: {
    categoryChunks() { return sliceIntoChunks(this.categoryDetails, 3) },
    categoryDetails() { return this.$store.state.categoryDetails.filter(el => el.title !== 'Tumor') },
    sampleMeta() { return this.$store.state.sampleMeta },
  },
}

function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}
</script>

<style scoped>
  .sample-dashboard {
      width: 75%;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      margin: 10px;
      padding-left: 10px;
      overflow: scroll;
  }
</style>