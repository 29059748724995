<template>
    <div class="input-container">
        <view-selector />
        <input-sample-dashboard :style="showSampleDashboard" /> 
        <input-heatmap :style="showHeatmap" />
    </div>
</template>

<script>
import InputHeatmap from './InputHeatmap.vue'
import InputSampleDashboard from './InputSampleDashboard.vue'
import ViewSelector from './ViewSelector.vue'

export default {
    components: {
        InputHeatmap,
        InputSampleDashboard,
        ViewSelector,
    },
    name: "input-container",
    computed: {
        view() { return this.$store.state.view },
        showHeatmap() {
            return this.view === 'Heatmap' ? "" : "display: none;"
        },
        showSampleDashboard() {
            return this.view === 'Sample dashboard' ? "" : "display: none;"
        },
    }
}
</script>

<style scoped>
    .input-container {
        max-width: 400px;
        min-width: 400px;
        border-top: none;
        padding-top: 8px;
        overflow-y: scroll;
        border-right: solid 1px lightgray;
}

    .input-container > div {
        margin-bottom: 1em;
    }

    .mssm-logo {
        margin: 2em;
    }
</style>