<template>
    <div class="input-sample-dashboard">
        <v-expansion-panels inset multiple v-model="panels">
            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">Cohort case counts</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <input-sample-dashboard-cohort-count-container />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">Show as percentages</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <input-sample-dashboard-percentage-selector />
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-3">Reorder graphs</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <input-sample-dashboard-graph-order-container />
                    <!-- <input-sample-dashboard-graph-order-container /> -->
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import InputSampleDashboardCohortCountContainer from './InputSampleDashboardCohortCountContainer.vue'
import InputSampleDashboardGraphOrderContainer from './InputSampleDashboardGraphOrderContainer.vue'
import InputSampleDashboardPercentageSelector from './InputSampleDashboardPercentageSelector.vue'

export default {
    components: {
        InputSampleDashboardCohortCountContainer,
        InputSampleDashboardPercentageSelector,
        InputSampleDashboardGraphOrderContainer,
    },
    
    name: "input-sample-dashboard",

    data() {
        return {
            panels: [0, 1],
        }
    },
}
</script>

<style scoped>
    .input-sample-dashboard {
        max-width: 400px;
        min-width: 400px;
        border-top: none;
        padding: 10px;
        overflow-y: scroll;
        border-right: solid 1px lightgray;
}

    .mssm-logo {
        margin: 2em;
    }
</style>