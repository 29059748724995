<template>
    <div class="input-heatmap">
        <v-tabs      
            fixed-tabs
            v-model="activeTabHeatmap"
        >
            <v-tab>
                Select pathways
            </v-tab>
            <v-tab>
                Interact
            </v-tab>
            <!-- <v-tab>
                Download
            </v-tab> -->
        </v-tabs>

        <v-tabs-items v-model="activeTabHeatmap">
            <v-tab-item><tab-1-select-input /></v-tab-item>
            <v-tab-item><tab-2-customize-and-download /></v-tab-item>
            <!-- <v-tab-item><tab-3-download /></v-tab-item> -->
        </v-tabs-items>
    </div>
</template>

<script>
import Tab1SelectInput from './Tab1_SelectInput.vue'
import Tab2CustomizeAndDownload from './Tab2_CustomizeAndDownload.vue'
// import Tab3Download from './Tab3_Download.vue'

export default {
    components: { Tab1SelectInput, Tab2CustomizeAndDownload },
    name: "input-heatmap",
    computed: {
        activeTabHeatmap: {
            get() {
                return this.$store.state.activeTabHeatmap
            },
            set(activeTabHeatmap) {
                this.$store.dispatch('updateActiveTabHeatmap', { activeTabHeatmap })
            }
        },
    }
}
</script>

<style scoped>
/* .input-heatmap {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-top: none;
    overflow-y: scroll;
    margin: 0 auto;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
} */
/* 
.tabs {
    background: pink;
    max-width: 400px;
    min-width: 400px;
}

.v-tabs-bar {
    background: pink !important;
} */

.v-item-group .theme--light .v-slide-group .v-slide-group--is-overflowing .v-tabs-bar .v-tabs-bar--is-mobile .primary--text {
    margin-left: 0px !important;
}

</style>