<template>
  <v-app>
    <introduction-header />
    <v-main>
      <div class="main">
        <input-container />
        <sample-dashboard :style="showSampleDashboard" />
        <heatmap-container :style="showHeatmapContainer" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import HeatmapContainer from './components/HeatmapContainer.vue';
import InputContainer from './components/InputContainer.vue';
import IntroductionHeader from './components/IntroductionHeader.vue';
import SampleDashboard from './components/SampleDashboard.vue';

export default {
  name: 'App',

  components: {
    IntroductionHeader,
    InputContainer,
    SampleDashboard,
    HeatmapContainer
  },

  data: () => ({
    //
  }),

  computed: {
    showHeatmapContainer() {
      return this.view === 'Heatmap' ? "" : "display: none;"
    },
    showSampleDashboard() {
      return this.view === 'Sample dashboard' ? "" : "display: none;"
    },
    view() { return this.$store.state.view },
  },

  mounted() {
    this.$store.dispatch('fetchPwIds')
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: row;
}
</style>